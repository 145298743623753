import api from './apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

export const useGetUnitsByHost = (hostId, query = {}) => {
  const getUnitsByHost = (key, hostId, query) => {
    return api.get(`bookingEngine/${hostId}/listing`, {
      params: { ...query }
    });
  };

  return useCustomQuery('units', [hostId, query], getUnitsByHost, { shouldFetch: !!hostId });
};

export const useGetUnitDetails = (hostId, unitId, onError) => {
  const getUnitDetails = (key, hostId, unitId) => {
    return api.get(`/bookingEngine/${hostId}/listing/${unitId}`);
  };

  return useCustomQuery('unitDetails', [hostId, unitId], getUnitDetails, {
    onError,
    shouldFetch: !!hostId && !!unitId
  });
};

export const useGetTotalPriceForBooking = ({ hostId, unitId, startDate, endDate } = {}) => {
  const getTotalPriceForBooking = (key, { hostId, unitId, startDate, endDate }) => {
    return api.get(`bookingEngine/${hostId}/listing/${unitId}/price`, { params: { startDate, endDate } });
  };

  return useCustomQuery('unitTotalPrice', [{ hostId, unitId, startDate, endDate }], getTotalPriceForBooking, {
    shouldFetch: !!startDate && !!endDate
  });
};

export const useGetUnitReservations = (hostId, unitId, startDate, endDate) => {
  const getUnitReservations = (key, hostId, unitId, startDate, endDate) => {
    return api.get(`bookingEngine/${hostId}/listing/${unitId}/reservations`, { params: { startDate, endDate } });
  };

  // console.log(40, getUnitReservations)

  return useCustomQuery('unitReservations', [hostId, unitId, startDate, endDate], getUnitReservations, {
    shouldFetch: !!startDate && !!endDate
  });
};
