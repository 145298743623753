import { Card, Row } from 'antd';
import styled from '@emotion/styled';

export const ContainerRow = styled(Row)`
  padding-bottom: 16px;

  @media (min-width: 1024px) {
    margin: 24px 0px;
    padding-bottom: 0px;
  }
`;

export const StyledCard = styled(Card)`
  margin-top: 16px;

  @media (min-width: 1024px) {
    margin-top: 0px;
    height: 100%;
  }
`;

export const CardTitle = styled.span`
  color: ${props => props.theme.color.text};
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
`;

export const SleepingArrangementContainer = styled.div`
  margin-bottom: 16px;
`;

export const SleepingArrangementNameRow = styled(Row)`
  color: ${props => props.theme.color.text};
  font-size: 16px;
  line-height: 32px;
  font-weight: bold;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 32px;
  }
`;

export const RoomDetailsRow = styled(Row)`
  color: ${props => props.theme.color.textSecondary};
  font-size: 16px;
  line-height: 32px;
`;
