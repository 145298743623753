import api from './apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

export const useGetRoomTypesByProperties = (hostId, propertyIds, { fields } = {}) => {
  const getRoomTypesByProperties = (key, hostId, propertyIds, fields) => {
    return api.get(`bookingEngine/${hostId}/roomType`, { params: { propertyIds, fields } });
  };

  return useCustomQuery('roomType', [hostId, propertyIds, fields], getRoomTypesByProperties, { shouldFetch: !!hostId });
};
