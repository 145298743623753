import React, { useMemo } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';

import Helmet from 'components/Helmet/Helmet';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { constructQueryAsObject, getSingularOrPluralLabel, guard } from 'utils/general';
import { buildBaseUri, buildHomePage } from 'utils/routes';
import { useGetHostById } from 'apis/host';

import { ConfirmationCodeContainer, ContainerDiv, DescriptionContainer, StyledButton, TickIcon, TitleContainer } from './ThankYou.styles';

const useFetchHost = () => {
  const { hostId } = useParams();

  const {
    isFetching,
    data: { host },
    error
  } = useGetHostById(hostId);

  const hostHBE = useMemo(() => guard(() => host.allowHotelBookingEngine, false), [host]);

  return { isFetching, hostHBE: hostHBE, error };
};

const ThankYou = ({ host }) => {
  const { search: urlParams } = useLocation();
  const { hostId } = useParams();
  const { hostHBE } = useFetchHost();

  const { confirmationCodes, totalAfterDiscount } = constructQueryAsObject(urlParams);
  const displayConfirmationCodes = !!confirmationCodes && confirmationCodes.split(',');
  return (
    <>
      <Helmet host={host} title="Thank you for your booking!" />
      <ContainerDiv>
        <TickIcon />
        <TitleContainer>Your booking is confirmed!</TitleContainer>
        <ConfirmationCodeContainer>
          Confirmation {getSingularOrPluralLabel(displayConfirmationCodes.length, 'code', { labelOnly: true })}:
        </ConfirmationCodeContainer>
        {!!displayConfirmationCodes && displayConfirmationCodes.map(code => <ConfirmationCodeContainer key={code}>{code}</ConfirmationCodeContainer>)}
        {!!totalAfterDiscount && Number(totalAfterDiscount) !== 0 && (
          <DescriptionContainer>Please pay the remaining RM{totalAfterDiscount} before check-in at property's reception.</DescriptionContainer>
        )}
        <DescriptionContainer>Thank you for choosing us.</DescriptionContainer>
        <DescriptionContainer>Please check your email for your booking details.</DescriptionContainer>
        <Link to={hostHBE === true ? buildHomePage(hostId) : buildBaseUri(hostId)}>
          <StyledButton type="primary">continue browsing</StyledButton>
        </Link>
      </ContainerDiv>
    </>
  );
};

export default withAppContext(ThankYou);
