import React from 'react';
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { ImageContainer, NextArrowContainer, PrevArrowContainer, StyledCloseButton, StyledImage, StyledModal } from './ImageGallery.styles';

const PrevArrow = ({ onClick }) => (
  <PrevArrowContainer>
    <LeftOutlined onClick={onClick} />
  </PrevArrowContainer>
);

const NextArrow = ({ onClick }) => (
  <NextArrowContainer>
    <RightOutlined onClick={onClick} />
  </NextArrowContainer>
);

const ImageGallery = ({ images, visible, onClose }) => (
  <StyledModal width="90%" closeIcon={<StyledCloseButton onClick={onClose} />} centered visible={visible} onCancel={onClose} footer={null}>
    <div>
      <Carousel swipeToSlide={true} infinite={true} arrows={true} prevArrow={<PrevArrow />} nextArrow={<NextArrow />}>
        {images.length > 0 &&
          images.map((photo, index) => (
            <ImageContainer key={index}>
              <StyledImage alt={`unit-detail-${index}`} src={photo} />
            </ImageContainer>
          ))}
      </Carousel>
    </div>
  </StyledModal>
);

export default ImageGallery;
