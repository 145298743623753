import styled from '@emotion/styled';
import { Form } from 'antd';

import FormSelection from 'components/Selection/FormSelection/FormSelection';

export const StyledForm = styled(Form)`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledPropertySelection = styled(FormSelection)`
  @media (min-width: 768px) {
    width: 25vw !important;
  }
`;

export const StyledRoomTypeSelection = styled(FormSelection)`
  @media (min-width: 768px) {
    width: 25vw !important;
  }
`;

export const StyledRoomViewsSelection = styled(FormSelection)`
  @media (min-width: 768px) {
    width: 15vw !important;
  }
`;

export const StyledSortingCriteriaSelection = styled(FormSelection)`
  @media (min-width: 768px) {
    width: 15vw !important;
  }
`;
