import React from 'react';
import { Col } from 'antd';

import { MainContainer } from './Footer.styles';

const Footer = ({ hostName }) => {
  return (
    <>
      <MainContainer>
        <Col>
          <span>{`@ 2022 ${hostName}`}</span>
        </Col>
        <Col>
          <span>Powered by HostPlatform</span>
        </Col>
      </MainContainer>
    </>
  );
};

export default Footer;
