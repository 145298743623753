import styled from '@emotion/styled';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export const PrevArrowContainer = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 0px;
  color: #acacac;
  z-index: 999;
  font-size: 32px;
  margin-left: -32px;
  text-align: center;
  cursor: pointer;
`;

export const NextArrowContainer = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  right: 0px;
  color: #acacac;
  z-index: 999;
  font-size: 32px;
  margin-right: -32px;
  text-align: center;
  cursor: pointer;
`;

export const StyledCloseButton = styled(CloseOutlined)`
  margin-left: 20px;
  cursor: pointer;
`;

export const StyledModal = styled(Modal)`
  height: 90vh;
  padding-left: 32px;
  padding-right: 32px;
`;

export const ImageContainer = styled.span`
  text-align: center;
  line-height: 60px;
  max-height: 90vh;
  background: #000000;
  overflow: hidden;
  padding-bottom: 40px;
`;

export const StyledImage = styled.img`
  display: inline-block !important;
  width: 100%;
  height: auto;
  margin: auto;
  max-width: 100%;
  max-height: 70vh;
  vertical-align: middle;
  color: #ffffff;

  @media (min-width: 768px) {
    width: auto;
    height: 100%;
  }
`;
