import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { Fab, Action } from 'react-tiny-fab';
// import 'react-tiny-fab/dist/styles.css';

import Helmet from 'components/Helmet/Helmet';

import { withUserContext } from 'contexts/UserContext/UserContext';

import { SORTING_CRITERIAS } from 'utils/constants';
import { formatToDateString } from 'utils/date';
import { guard } from 'utils/general';

import { useFetchConstant } from 'apis/constants';
import { useGetUnitsByHost } from 'apis/listing';
import { useGetPropertiesByHost } from 'apis/property';
import { useGetRoomTypesByProperties } from 'apis/roomType';
import { useGetHostById } from 'apis/host';

import Header from 'pages/UnitListing/Header/Header';
import Body from './Body/Body';

import { StyledBody, StyledHeader } from './Homepage.styles';

export const useFetchHost = () => {
  const { hostId } = useParams();

  const {
    isFetching,
    data: { host },
    error
  } = useGetHostById(hostId);

  const localHost = useMemo(() => guard(() => host, {}), [host]);

  return { isFetching, host: localHost, error };
};

const useFetchConstants = () => {
  const { isLoading: isLoadingStateMY, selection: stateMYSelection } = useFetchConstant('statesMY');
  const { isLoading: isLoadingRoomViews, selection: roomViewsSelection } = useFetchConstant('roomAmenities');

  const formattedRoomViewsSelection = useMemo(() => roomViewsSelection.filter(roomView => roomView.type === 'Outdoor and View'), [
    roomViewsSelection
  ]);

  const isLoadingConstants = useMemo(() => isLoadingStateMY || isLoadingRoomViews, [isLoadingStateMY, isLoadingRoomViews]);

  return {
    isLoadingConstants,
    stateMYSelection,
    roomViewsSelection: formattedRoomViewsSelection
  };
};

const useFetchProperties = (hostId, cacheCheckInDate, cacheCheckOutDate) => {
  const {
    data: { properties }
  } = useGetPropertiesByHost(hostId, {
    fields: ['_id', 'name', 'bookingEngine', 'state', 'images'],
    startDate: formatToDateString(cacheCheckInDate),
    endDate: formatToDateString(cacheCheckOutDate)
  });
  const propertySelection = useMemo(
    () =>
      guard(
        () =>
          properties.map(property => ({ value: property._id, label: property.bookingEngine ? property.bookingEngine.displayName : property.name })),
        []
      ),
    [properties]
  );
  return { propertySelection, properties };
};

const useFetchRoomTypes = (hostId, propertyId, propertySelection, cacheState, cacheCheckInDate, cacheCheckOutDate) => {
  const propertyIds = !!propertyId ? [propertyId] : propertySelection.map(property => property.value);

  const {
    data: { roomTypes }
  } = useGetRoomTypesByProperties(
    hostId,
    propertyIds,
    { fields: ['_id', 'name', 'capacity', 'amenities', 'roomSizeInSqFt', 'bedrooms', 'description', 'images', 'bookingEngine'] },
    cacheState,
    formatToDateString(cacheCheckInDate),
    formatToDateString(cacheCheckOutDate)
  );

  const roomTypeSelection = useMemo(
    () => guard(() => roomTypes.map(roomType => ({ value: roomType._id, label: roomType.bookingEngine.externalDisplayName ?? roomType.name })), []),
    [roomTypes]
  );
  // console.log(roomTypes);

  return { roomTypeSelection, roomTypes };
};

const useFetchUnits = (
  hostId,
  cacheState,
  cacheCheckInDate,
  cacheCheckOutDate,
  cacheAdultPax,
  cacheChildPax,
  cachePropertyId,
  cacheRoomTypeId,
  cacheRoomViews,
  cacheSortingCriteria
) => {
  const query = {
    state: cacheState,
    checkInDate: formatToDateString(cacheCheckInDate),
    checkOutDate: formatToDateString(cacheCheckOutDate),
    adultPax: cacheAdultPax,
    childPax: cacheChildPax,
    propertyId: cachePropertyId,
    roomTypeId: cacheRoomTypeId,
    roomViews: cacheRoomViews
  };
  const { isLoading: isLoadingUnits, data } = useGetUnitsByHost(hostId, query);
  const units = data.units || [];
  const totalNumberOfUnits = units.length;
  const unitRoomTypeJSON = groupByKey(units, 'roomType._id');
  var unitRoomType = [];
  for (var i in unitRoomTypeJSON) unitRoomType.push([i, unitRoomTypeJSON[i]]);

  const sortedUnits = useMemo(
    () =>
      cacheSortingCriteria === 'priceAscending'
        ? units.sort((current, next) => current.pricePerNight - next.pricePerNight)
        : units.sort((current, next) => next.pricePerNight - current.pricePerNight),
    [cacheSortingCriteria, units]
  );

  return { isLoadingUnits, units: sortedUnits, totalNumberOfUnits, unitRoomType };
};

function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj.roomType._id === undefined) return hash;
    return Object.assign(hash, { [obj.roomType._id]: (hash[obj.roomType._id] || []).concat(obj) });
  }, {});
}

const HomePage = ({ checkIsUnitInBooking, searchAndFilter, updateSearchAndFilter }) => {
  const { hostId } = useParams();
  const { host } = useFetchHost();

  const {
    state: cacheState,
    checkInDate: cacheCheckInDate,
    checkOutDate: cacheCheckOutDate,
    adultPax: cacheAdultPax,
    childPax: cacheChildPax,
    propertyId: cachePropertyId,
    roomTypeId: cacheRoomTypeId,
    roomViews: cacheRoomViews,
    sortingCriteria: cacheSortingCriteria
  } = searchAndFilter;

  const [roomTypePropertyId, setRoomTypePropertyId] = useState(cachePropertyId);

  /* ---------------------------------------------fetching data details--------------------------------------------- */
  const { isLoadingConstants, stateMYSelection, roomViewsSelection } = useFetchConstants();
  const { propertySelection, properties } = useFetchProperties(hostId, cacheCheckInDate, cacheCheckOutDate);
  const { roomTypeSelection } = useFetchRoomTypes(hostId, roomTypePropertyId, propertySelection, cacheState, cacheCheckInDate, cacheCheckOutDate);
  const { isLoadingUnits, units, totalNumberOfUnits } = useFetchUnits(
    hostId,
    cacheState,
    cacheCheckInDate,
    cacheCheckOutDate,
    cacheAdultPax,
    cacheChildPax,
    cachePropertyId,
    cacheRoomTypeId,
    cacheRoomViews,
    cacheSortingCriteria
  );

  /* ----------------------------------------------isLoading--------------------------------------------- */
  const isLoading = useMemo(() => isLoadingConstants, [isLoadingConstants]);

  /* ---------------------------------------------functions--------------------------------------------- */
  const handleOnPropertyChange = (propertyId, shouldFetchUnits = true) => {
    setRoomTypePropertyId(propertyId);

    if (shouldFetchUnits) {
      updateSearchAndFilter({ propertyId: propertyId || null, roomTypeId: null });
    }
  };

  const handleOnRoomTypeChange = roomTypeId => {
    updateSearchAndFilter({ roomTypeId: roomTypeId || null });
  };

  const handleOnRoomViewsChange = roomViews => {
    updateSearchAndFilter({ roomViews: roomViews || null });
  };

  const handleOnSortingCriteriaChange = sortingCriteria => {
    updateSearchAndFilter({ sortingCriteria: sortingCriteria || null });
  };

  const handleOnSearchButtonClick = (state, checkInDate, checkOutDate, adultPax, childPax) => {
    updateSearchAndFilter({
      state: state || null,
      checkInDate: checkInDate || null,
      checkOutDate: checkOutDate || null,
      adultPax,
      childPax
    });
  };

  const handleOnFilterModalConfirm = (propertyId, roomTypeId, roomViews, sortingCriteria) => {
    updateSearchAndFilter({
      propertyId: propertyId || null,
      roomTypeId: roomTypeId || null,
      roomViews: roomViews || null,
      sortingCriteria
    });
  };

  return (
    <>
      <Helmet title="Book your stay from your favourite hotel" />
      <StyledHeader loading={isLoading} active>
        <Header
          stateMYSelection={stateMYSelection}
          roomViewsSelection={roomViewsSelection}
          sortingCriteriaSelection={SORTING_CRITERIAS}
          propertySelection={propertySelection}
          roomTypeSelection={roomTypeSelection}
          state={cacheState}
          checkInDate={cacheCheckInDate}
          checkOutDate={cacheCheckOutDate}
          adultPax={cacheAdultPax}
          childPax={cacheChildPax}
          propertyId={cachePropertyId}
          roomTypeId={cacheRoomTypeId}
          roomViews={cacheRoomViews}
          sortingCriteria={cacheSortingCriteria}
          totalNumberOfUnits={totalNumberOfUnits}
          onPropertyChange={handleOnPropertyChange}
          onRoomTypeChange={handleOnRoomTypeChange}
          onRoomViewsChange={handleOnRoomViewsChange}
          onSortingCriteriachange={handleOnSortingCriteriaChange}
          onSearchButtonClick={handleOnSearchButtonClick}
          onFilterModalConfirm={handleOnFilterModalConfirm}
          host={host}
          homepage={true}
        />
      </StyledHeader>
      <StyledBody loading={isLoadingUnits} active>
        <Body
          stateMYSelection={stateMYSelection}
          hostId={hostId}
          properties={properties}
          filterState={cacheState}
          checkInDate={cacheCheckInDate}
          checkOutDate={cacheCheckOutDate}
        />
      </StyledBody>
    </>
  );
};

export default withUserContext(HomePage);
