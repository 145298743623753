import styled from '@emotion/styled';
import { Avatar } from 'antd';

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
`;

export const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

export const StyledTab = styled.span`
  cursor: pointer;
  font-size: 16px;
  line-height: 32px;
`;
