import { Row } from 'antd';
import styled from '@emotion/styled';

export const UnitNameRow = styled(Row)`
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  margin-top: 24px;
  color: ${props => props.theme.color.text};

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 48px;
    margin-top: 0px;
  }
`;

export const UnitLocationRow = styled(Row)`
  font-size: 16px;
  line-height: 32px;
  color: ${props => props.theme.color.textSecondary};

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

export const UnitOccupancyRow = styled(Row)`
  font-size: 16px;
  line-height: 32px;
  margin-top: 32px;
  margin-bottom: 8px;
  color: ${props => props.theme.color.text};

  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 48px;
  }
`;
