import React from 'react';

import Image, { NoImage } from '../BareImage/BareImage';

import { MainContainer, StyledCarousel } from './ImageCarousel.styles';

const ImageCarousel = ({ images, alt, onClick }) => {
  return (
    <MainContainer>
      <StyledCarousel>
        {images && images.length > 0 ? (
          images.map(image => <Image key={image} image={image} alt={alt} onClick={onClick} />)
        ) : (
          <NoImage alt={alt} onClick={onClick} />
        )}
      </StyledCarousel>
    </MainContainer>
  );
};

export default ImageCarousel;
