import styled from '@emotion/styled';
import { Button } from 'antd';

export const ConfirmButton = styled(Button)`
  text-transform: uppercase;
  margin: 32px auto 0px;
  width: 200px;
`;

export const StyledTextTitle = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.color.text};
`;
