import styled from '@emotion/styled';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  color: ${props => props.theme.color.text};
`;

export const TickIcon = styled(CheckCircleFilled)`
  color: ${props => props.theme.color.success} !important;
  font-size: 96px;

  @media (min-width: 768px) {
    font-size: 128px;
  }
`;

export const TitleContainer = styled.span`
  font-size: 24px;
  font-weight: bolder;
  margin: 24px 0px 16px;

  @media (min-width: 768px) {
    font-size: 36px;
  }
`;

export const ConfirmationCodeContainer = styled.span`
  font-size: 20px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 28px;
  }
`;

export const DescriptionContainer = styled.span`
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 16px;
  height: auto;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;
