import React, { useState, useEffect } from 'react';
import { Card, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import FormInput from 'components/Input/FormInput/FormInput';

import { ConfirmButton, StyledTextTitle } from './RemarksModal.styles';

const { TextArea } = Input;

const RemarksModal = ({ form, unit, onConfirm }) => {
  // const { name, roomTypeName } = unit;
  const [editedRemarks, setEditedRemarks] = useState('');

  useEffect(() => {
    const { remarks } = unit;
    setEditedRemarks(remarks);
  }, [unit]);

  const handleOnChangeRemarks = e => {
    setEditedRemarks(e.target.value);
  };

  const handleOnConfirm = () => {
    onConfirm(editedRemarks);
  };

  return (
    <Card title={<StyledTextTitle>Remarks</StyledTextTitle>}>
      <FormInput
        form={form}
        name="remarks"
        type="textarea"
        size="large"
        // placeholder="Enter your first name"
        // requiredErrorMessage="Please enter your first name"
      />
      {/* <TextArea
        form={form}
        name="remarks"
        rows={4}
      /> */}
    </Card>
  );
};

RemarksModal.propTypes = {
  unit: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired
};

RemarksModal.defaultProps = {
  unit: {},
  visible: false,
  onConfirm: () => {}
};

export default RemarksModal;
