import React from 'react';
import { useHistory } from 'react-router-dom';
import { Empty, Row } from 'antd';

import { constructDisplayPrice, getSingularOrPluralLabel } from 'utils/general';
import { buildUnitDetailUri } from 'utils/routes';

import Image from 'components/Image/BareImage/BareImage';

import {
  AlreadyBookedIcon,
  AlreadyBookedRow,
  CardContainer,
  StyledButton,
  StyledCard,
  StyledCardImageContainer,
  StyledEmpty,
  StyledTextUnitAddress,
  StyledTextUnitName,
  StyledTextPax,
  StyledTextUnitPrice,
  StyledTextUnitPriceLabel
} from './Body.styles';

const CardHeader = ({ image, onClick }) => {
  return (
    <StyledCardImageContainer>
      <Image image={image} alt="View of the unit" onClick={onClick} />
    </StyledCardImageContainer>
  );
};

const CardBody = ({ unitName, unitCity, unitState, unitPricePerNight, unitAdultPax, unitChildPax, onClick }) => {
  const unitAddress = `${unitCity}, ${unitState}`;
  const unitPax = `${getSingularOrPluralLabel(unitAdultPax, 'Adult')}, ${getSingularOrPluralLabel(unitChildPax, 'Child', {
    pluralLabel: 'Children'
  })}`;

  return (
    <div onClick={onClick}>
      <StyledTextUnitName>{unitName}</StyledTextUnitName>
      <StyledTextUnitAddress>{unitAddress}</StyledTextUnitAddress>

      <div>
        <StyledTextUnitPrice>{constructDisplayPrice(unitPricePerNight)}</StyledTextUnitPrice>
        <StyledTextUnitPriceLabel> / Night</StyledTextUnitPriceLabel>
      </div>
      <StyledTextPax>{unitPax}</StyledTextPax>
    </div>
  );
};

const CardFooter = ({ isUnitBooked, onClickAddUnit }) => {
  return isUnitBooked ? (
    <AlreadyBookedRow justify="center" align="middle">
      <AlreadyBookedIcon />
      Added to booking
    </AlreadyBookedRow>
  ) : (
    <StyledButton type="primary" onClick={onClickAddUnit}>
      add to booking
    </StyledButton>
  );
};

const Body = ({ stateMYSelection, hostId, units, checkIsUnitInBooking, onClickAddUnit }) => {
  const history = useHistory();

  const handleOnUnitClick = unitId => () => {
    history.push(buildUnitDetailUri(hostId, unitId));
  };

  return units.length > 0 ? (
    <Row>
      {units.map(unit => {
        const unitId = unit._id;
        const firstImage = unit.image;
        const unitName = unit.name;
        const unitCity = unit.roomType.property.city;
        const unitState = stateMYSelection.find(state => state.value === unit.roomType.property.state).label;
        const unitPricePerNight = unit.pricePerNight;
        const unitAdultPax = unit.roomType.capacity.adult;
        const unitChildPax = unit.roomType.capacity.children;

        const isUnitBooked = checkIsUnitInBooking(unitId);

        return (
          <CardContainer key={unitId} span={24} md={8} xl={4}>
            <StyledCard cover={<CardHeader image={firstImage} onClick={handleOnUnitClick(unitId)} />}>
              <CardBody
                unitName={unitName}
                unitCity={unitCity}
                unitState={unitState}
                unitPricePerNight={unitPricePerNight}
                unitAdultPax={unitAdultPax}
                unitChildPax={unitChildPax}
                onClick={handleOnUnitClick(unitId)}
              />
            </StyledCard>
            <CardFooter isUnitBooked={isUnitBooked} onClickAddUnit={onClickAddUnit(unit)} />
          </CardContainer>
        );
      })}
    </Row>
  ) : (
    <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No units available based on your search" />
  );
};

export default Body;
