import styled from '@emotion/styled';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Row } from 'antd';

export const CardContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 28px;

  :hover {
    cursor: pointer;
  }
`;

export const StyledCard = styled(Card)`
  width: 90%;
  height: 100%;
`;

export const StyledCardImageContainer = styled.div`
  min-height: 280px;
  height: 40vh;
`;

export const StyledTextUnitName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.color.text};
`;

export const StyledTextUnitAddress = styled.div`
  color: ${props => props.theme.color.textSecondary};
  margin-bottom: 16px;
`;

export const StyledTextAvailability = styled.div`
  color: red;
`;

export const StyledTextUnitPrice = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.color.text};
`;

export const StyledTextUnitPriceLabel = styled.span`
  color: ${props => props.theme.color.textSecondary};
`;

export const StyledTextPax = styled.div`
  color: ${props => props.theme.color.textSecondary};
`;

export const StyledButton = styled(Button)`
  width: 90%;
  height: 40px;
  text-transform: uppercase;
`;

export const AlreadyBookedRow = styled(Row)`
  width: 90%;
  height: 40px;
  text-transform: uppercase;
  background-color: limegreen;
  color: white;
`;

export const AlreadyBookedIcon = styled(CheckCircleOutlined)`
  margin-right: 4px;
`;

export const StyledEmpty = styled(Empty)`
  margin-top: 80px;
`;
