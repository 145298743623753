import React, { useState, useMemo } from 'react';
import { Col, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useGetUnitDetails } from 'apis/listing';

import Helmet from 'components/Helmet/Helmet';

import { withUserContext } from 'contexts/UserContext/UserContext';

import ImageCarousel from 'components/Image/ImageCarousel/ImageCarousel';
import ImageGallery from 'components/Image/ImageGallery/ImageGallery';

import { buildBaseUri, build404Uri } from 'utils/routes';

import { BackButton } from './UnitDetails.styles';

import AddToBookingCard from './components/AddToBookingCard/AddToBookingCard';
import Description from './components/Description/Description';
import GeneralInfo from './components/GeneralInfo/GeneralInfo';
import RoomInfo from './components/RoomInfo/RoomInfo';
import SectionDivider from './components/SectionDivider/SectionDivider';

// TODO: Recheck Unit details flow later on after some other API fetch request change
const useFetchUnitDetails = () => {
  const { hostId, unitId } = useParams();
  const history = useHistory();

  const handleUnitDetailsError = () => {
    history.push(build404Uri());
  };

  const { data } = useGetUnitDetails(hostId, unitId, handleUnitDetailsError);

  const unit = useMemo(() => data.unit || {}, [data]);

  return unit;
};

const BackToHostListingButton = () => {
  const { hostId } = useParams();

  return (
    <Link to={buildBaseUri(hostId)}>
      <BackButton icon={<LeftOutlined />}>Back to View All</BackButton>
    </Link>
  );
};

const UnitDetails = ({ searchAndFilter, addUnitBooking, checkIsUnitInBooking }) => {
  const unit = useFetchUnitDetails();

  const [isImageGalleryVisible, setIsImageGalleryVisible] = useState(false);

  const isUnitBooked = useMemo(() => checkIsUnitInBooking(unit._id), [checkIsUnitInBooking, unit._id]);

  const { amenities, description, extraCharges, images, name, pricePerNight, sleepingArrangements } = unit;
  const { checkInDate, checkOutDate } = searchAndFilter;

  const handleOnClickImage = () => {
    setIsImageGalleryVisible(true);
  };

  const handleOnCloseImageGallery = () => {
    setIsImageGalleryVisible(false);
  };

  const handleOnAddUnitToBooking = (startDate, endDate, actualPricePerNight) => {
    const image = unit.images.length > 0 ? unit.images[0] : '';

    const formattedUnit = {
      ...unit,
      ...(!isNaN(actualPricePerNight) && { pricePerNight: actualPricePerNight }),
      image
    };

    addUnitBooking({ startDate, endDate, unit: formattedUnit });
  };

  return unit && Object.keys(unit).length > 0 ? (
    <>
      <Helmet title={name} />
      <BackToHostListingButton />
      <Row gutter={{ md: 48 }}>
        <Col span={24} md={8}>
          <ImageCarousel images={images} alt={name} onClick={handleOnClickImage} />
          <AddToBookingCard
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            isUnitBooked={isUnitBooked}
            pricePerNight={pricePerNight}
            extraCharges={extraCharges}
            onAddUnitToBooking={handleOnAddUnitToBooking}
          />
        </Col>
        <Col span={24} md={16}>
          <GeneralInfo unit={unit} />
          <SectionDivider />
          <RoomInfo amenities={amenities} sleepingArrangements={sleepingArrangements} />
          <SectionDivider />
          <Description description={description} />
        </Col>
      </Row>
      {isImageGalleryVisible && <ImageGallery images={unit.images} visible={isImageGalleryVisible} onClose={handleOnCloseImageGallery} />}
    </>
  ) : null;
};

export default withUserContext(UnitDetails);
