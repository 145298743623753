export const buildBaseUri = (hostId = ':hostId') => `/${hostId}`;

export const buildHomePage = (hostId = ':hostId') => `/${hostId}/homepage`;

export const buildPropertyBaseUri = (hostId = ':hostId', propertyId = ':propertyId') => `${buildBaseUri(hostId)}/property=${propertyId}`;

export const buildCheckOutUri = (hostId = ':hostId', propertyId = ':propertyId') => `${buildBaseUri(hostId)}/property=${propertyId}/checkout`;

export const build404Uri = () => '/404';

export const buildThankYouUri = hostId => `${buildBaseUri(hostId)}/thankyou`;

export const buildUnitDetailUri = (hostId = ':hostId', unitId = ':unitId') => `${buildBaseUri(hostId)}/listing/${unitId}`;

export const buildStripPaymentUri = hostId => `${buildBaseUri(hostId)}/stripe-payment`;
