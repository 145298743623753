import React, { useState, useMemo } from 'react';
import { useCookies } from 'react-cookie';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { BLANK_IMAGE } from 'utils/constants';
import { guard } from 'utils/general';
import { Fab } from 'react-tiny-fab';
import { MdShoppingCart } from 'react-icons/md';
import { Badge, Col } from 'antd';

import BookingDrawer from './components/BookingDrawer/BookingDrawer';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import { StyledHeader, StyledBody, StyledFooter, StyledUpButton } from './StandardLayout.styles';

const useHostDetails = host => {
  const hostHomepageUrl = useMemo(
    () => guard(() => host.bookingEngine.domain, '') || guard(() => (host.allowHotelBookingEngine ? `/${host._id}/homepage` : `/${host._id}`), ''),
    [host]
  );
  const hostImageURL = useMemo(() => guard(() => host.images[0].imageUrl, BLANK_IMAGE), [host]);
  const hostName = useMemo(() => guard(() => host.name, ''), [host]);
  const hostStripeConnectId = useMemo(() => guard(() => host.bookingEngine.paymentInfo.stripeConnectAccountId), [host]);
  const hostHBE = useMemo(() => guard(() => host.allowHotelBookingEngine, false), [host]);
  const hostPrimaryColor = guard(() => host.bookingEngine.primaryColor, 'rgb(0,188,212)');

  return { hostHomepageUrl, hostImageURL, hostName, hostStripeConnectId, hostHBE, hostPrimaryColor };
};

const useDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOnOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleOnCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  return { isDrawerOpen, handleOnOpenDrawer, handleOnCloseDrawer };
};

const StandardLayout = ({ children, host }) => {
  const { hostHomepageUrl, hostImageURL, hostName, hostStripeConnectId, hostHBE, hostPrimaryColor } = useHostDetails(host);

  const { isDrawerOpen, handleOnOpenDrawer, handleOnCloseDrawer } = useDrawer();

  const [cookies] = useCookies(['booking']);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const userBooking = useMemo(() => guard(() => cookies['booking'], {}), [cookies]);

  let roomType = [];
  const units = userBooking.units;

  if (units !== undefined) {
    for (let i = 0; i < units.length; i++) {
      if (roomType.length === 0) {
        roomType.push(units[i]);
      } else {
        for (let j = 0; j < roomType.length; j++) {
          if (
            roomType.some(element => {
              if (units[i].roomTypeId === element.roomTypeId) return true;
            })
          ) {
          } else {
            roomType.push(units[i]);
          }
        }
      }
    }
  }

  const number = hostHBE ? guard(() => roomType.length, 0) : guard(() => userBooking.units.length, 0); //guard(() => roomType.length, userBooking.units.length);

  const checkOut = window.location.href.includes('checkout');
  const thankYou = window.location.href.includes('thankyou');
  const homePage = window.location.href.includes('homepage');

  const hideDrawer = checkOut || thankYou || homePage;
  return (
    <>
      {!hideDrawer && <BookingDrawer visible={isDrawerOpen} hostStripeConnectId={hostStripeConnectId} onCloseDrawer={handleOnCloseDrawer} />}
      <StyledHeader>
        <Header
          hostHomepageUrl={hostHomepageUrl}
          hostImageURL={hostImageURL}
          onOpenDrawer={handleOnOpenDrawer}
          count={number}
          hideDrawer={hideDrawer}
        />
      </StyledHeader>
      <StyledBody>{children}</StyledBody>
      <>
        {!isDrawerOpen && !hideDrawer && (
          <Col>
            <Fab icon={<StyledUpButton color="black" size="20px" />} event="click" onClick={scrollToTop} style={{ bottom: 100, right: 24 }}></Fab>
            <Fab
              mainButtonStyles={{ backgroundColor: hostPrimaryColor }}
              icon={
                <Badge count={number} offset={[15, -15]}>
                  <MdShoppingCart color="white" size="20px" />
                </Badge>
              }
              event={'click'}
              alwaysShowTitle={false}
              onClick={isDrawerOpen ? handleOnCloseDrawer : handleOnOpenDrawer}
              style={{ bottom: 24, right: 24 }}
            ></Fab>
          </Col>
        )}
      </>
      <StyledFooter>
        <Footer hostName={hostName} />
      </StyledFooter>
    </>
  );
};

export default withAppContext(StandardLayout);
