import React from 'react';
import { Col, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import FormDateRangePicker from 'components/DateRangePicker/FormDateRangePicker/FormDateRangePicker';
import FormInputNumber from 'components/InputNumber/FormInputNumber/FormInputNumber';
import FormSelection from 'components/Selection/FormSelection/FormSelection';
import { REGEX_NOT_NUMBER_N_DOT } from 'utils/constants';
import { getSingularOrPluralLabel, guard } from 'utils/general';

import { SearchBackground, SearchContainer, StyledButton } from './SearchBar.styles';

const SearchBar = ({ stateMYSelection, state, checkInDate, checkOutDate, adultPax, childPax, onSearchButtonClick, host }) => {
  const [form] = Form.useForm();

  const handleOnSearchButtonClick = e => {
    e.preventDefault();
    form
      .validateFields()
      .then(values => {
        const state = values.destination;
        const checkInDate = guard(() => values.dateRange[0]);
        const checkOutDate = guard(() => values.dateRange[1]);
        const adultPax = values.adultPax;
        const childPax = values.childPax;

        onSearchButtonClick(state, checkInDate, checkOutDate, adultPax, childPax);
      })
      .catch(() => {});
  };

  if (host.allowBookingEngine) {
    return (
      <SearchBackground>
        <Form
          form={form}
          initialValues={{
            destination: state,
            dateRange: [checkInDate, checkOutDate],
            adultPax,
            childPax
          }}
        >
          <SearchContainer>
            <Col span={24} md={4}></Col>
            <Col span={24} md={4}>
              <FormSelection name="destination" selections={stateMYSelection} placeholder="Select your destination" />
            </Col>
            <Col span={24} md={6}>
              <FormDateRangePicker name="dateRange" placeholder={['Check-in', 'Check-out']} />
            </Col>
            <Col span={12} md={3}>
              <FormInputNumber
                name="adultPax"
                minValue={1}
                maxValue={36}
                formatter={value => getSingularOrPluralLabel(value, 'Adult')}
                parser={value => value.replace(REGEX_NOT_NUMBER_N_DOT, '')}
              />
            </Col>
            <Col span={12} md={3}>
              <FormInputNumber
                name="childPax"
                minValue={0}
                maxValue={18}
                formatter={value => getSingularOrPluralLabel(value, 'Child', { pluralLabel: 'Children' })}
                parser={value => value.replace(REGEX_NOT_NUMBER_N_DOT, '')}
              />
            </Col>
            <Col span={24} md={1}>
              <StyledButton type="primary" icon={<SearchOutlined />} onClick={handleOnSearchButtonClick} />
            </Col>
          </SearchContainer>
        </Form>
      </SearchBackground>
    );
  } else {
    return (
      <SearchBackground>
        <Form
          form={form}
          initialValues={{
            destination: state,
            dateRange: [checkInDate, checkOutDate],
            adultPax
          }}
        >
          <SearchContainer>
            <Col span={24} md={4}></Col>
            <Col span={24} md={6}>
              <FormSelection name="destination" selections={stateMYSelection} placeholder="State" />
            </Col>
            <Col span={24} md={6}>
              <FormDateRangePicker name="dateRange" placeholder={['Check-in', 'Check-out']} />
            </Col>
            <Col span={24} md={3}>
              <FormInputNumber
                name="adultPax"
                minValue={0}
                maxValue={36}
                // formatter={value => getSingularOrPluralLabel(value, 'No. of Guest')}
                placeholder={'No. of Guest'}
                parser={value => value.replace(REGEX_NOT_NUMBER_N_DOT, '')}
              />
            </Col>
            <Col span={24} md={1}>
              <StyledButton type="primary" icon={<SearchOutlined />} onClick={handleOnSearchButtonClick} />
            </Col>
          </SearchContainer>
        </Form>
      </SearchBackground>
    );
  }
};

export default SearchBar;
