import React from 'react';
import { Col } from 'antd';
import { Helmet } from 'react-helmet';

import placeHolderImage from './images/404.svg';

import { ErrorContainer, ErrorSubtitle, ErrorTitle, Image, MainContainer, StyledCol, StyledRow } from './NotFound404.styles';

const NotFound404 = () => {
  return (
    <>
      <Helmet>
        <title>404 - Page Not Found</title>
      </Helmet>
      <MainContainer>
        <StyledRow type="flex" align="middle">
          <StyledCol span={24} md={10}>
            <Image src={placeHolderImage} alt="not-found-page" />
          </StyledCol>
          <Col span={0} md={1} />
          <StyledCol span={24} md={11}>
            <ErrorContainer>
              <ErrorTitle>404 Oops!</ErrorTitle>
              <ErrorSubtitle>
                Looks like the page you are looking for does not exist. Please return to previous page and contact our support.
              </ErrorSubtitle>
            </ErrorContainer>
          </StyledCol>
        </StyledRow>
      </MainContainer>
    </>
  );
};

export default NotFound404;
