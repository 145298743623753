import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
// import { Fab, Action } from 'react-tiny-fab';
// import 'react-tiny-fab/dist/styles.css';

import Helmet from 'components/Helmet/Helmet';

import { withUserContext } from 'contexts/UserContext/UserContext';

import { SORTING_CRITERIAS } from 'utils/constants';
import { formatToDateString } from 'utils/date';
import { guard } from 'utils/general';

import { useFetchConstant } from 'apis/constants';
import { useGetUnitsByHost } from 'apis/listing';
import { useGetPropertiesByHost } from 'apis/property';
import { useGetRoomTypesByProperties } from 'apis/roomType';
import { useGetHostById } from 'apis/host';

import Header from './Header/Header';
import Body from './Body/Body';
import HotelBody from './HotelBody/HotelBody';

import { StyledBody, StyledHeader } from './UnitListing.styles';

export const useFetchHost = () => {
  const { hostId } = useParams();

  const {
    isFetching,
    data: { host },
    error
  } = useGetHostById(hostId);

  const localHost = useMemo(() => guard(() => host, {}), [host]);

  return { isFetching, host: localHost, error };
};

const useFetchConstants = () => {
  const { isLoading: isLoadingStateMY, selection: stateMYSelection } = useFetchConstant('statesMY');
  const { isLoading: isLoadingRoomViews, selection: roomViewsSelection } = useFetchConstant('roomAmenities');

  const formattedRoomViewsSelection = useMemo(() => roomViewsSelection.filter(roomView => roomView.type === 'Outdoor and View'), [
    roomViewsSelection
  ]);

  const isLoadingConstants = useMemo(() => isLoadingStateMY || isLoadingRoomViews, [isLoadingStateMY, isLoadingRoomViews]);

  return {
    isLoadingConstants,
    stateMYSelection,
    roomViewsSelection: formattedRoomViewsSelection
  };
};

const useFetchProperties = (hostId, allowBookingEngine = true) => {
  const { propertyId } = useParams();

  const {
    data: { properties }
  } = useGetPropertiesByHost(hostId, { fields: ['_id', 'name', 'displayName', 'bookingEngine', 'addOns'] });

  let propertySelection = useMemo(
    () =>
      guard(
        () =>
          properties.map(property => ({
            value: property._id,
            label: property.bookingEngine ? property.bookingEngine.displayName : property.name
          })),
        []
      ),
    [properties]
  );

  if (!allowBookingEngine) {
    const filteredPropSelection = propertySelection.filter(prop => prop.value === propertyId);
    const filteredProperties = properties ? properties.filter(prop => prop._id === propertyId) : [];
    return { propertySelection: filteredPropSelection, filteredProperties };
  }
  return { propertySelection };
};

const useFetchRoomTypes = (hostId, propertyId, propertySelection, cacheState, cacheCheckInDate, cacheCheckOutDate) => {
  const propertyIds = !!propertyId ? [propertyId] : propertySelection.map(property => property.value);

  const {
    data: { roomTypes }
  } = useGetRoomTypesByProperties(
    hostId,
    propertyIds,
    { fields: ['_id', 'name', 'capacity', 'amenities', 'roomSizeInSqFt', 'bedrooms', 'description', 'images', 'bookingEngine'] },
    cacheState,
    formatToDateString(cacheCheckInDate),
    formatToDateString(cacheCheckOutDate)
  );

  const roomTypeSelection = useMemo(
    () => guard(() => roomTypes.map(roomType => ({ value: roomType._id, label: roomType.bookingEngine.externalDisplayName ?? roomType.name })), []),
    [roomTypes]
  );
  return { roomTypeSelection, roomTypes };
};

const useFetchUnits = (
  hostId,
  cacheState,
  cacheCheckInDate,
  cacheCheckOutDate,
  cacheAdultPax,
  cacheChildPax,
  cachePropertyId,
  cacheRoomTypeId,
  cacheRoomViews,
  cacheSortingCriteria,
  allowHotelBookingEngine
) => {
  const { propertyId } = useParams();
  const query = {
    state: cacheState,
    checkInDate: formatToDateString(cacheCheckInDate),
    checkOutDate: formatToDateString(cacheCheckOutDate),
    adultPax: cacheAdultPax,
    childPax: cacheChildPax,
    propertyId: propertyId ? propertyId : cachePropertyId,
    roomTypeId: cacheRoomTypeId,
    roomViews: cacheRoomViews,
    isHotelBookingEngine: allowHotelBookingEngine ? allowHotelBookingEngine : undefined
  };
  const { isLoading: isLoadingUnits, data } = useGetUnitsByHost(hostId, query);
  const units = data.units || [];
  const property = units[0] && units[0].roomType && units[0].roomType.property ? units[0].roomType.property : {};
  const totalNumberOfUnits = units.length;
  const unitRoomTypeJSON = groupByKey(units, 'roomType._id');
  var unitRoomType = [];
  for (var i in unitRoomTypeJSON) unitRoomType.push([i, unitRoomTypeJSON[i]]);
  const totalNumberOfRooms = unitRoomType.length;

  const sortedUnits = useMemo(
    () =>
      cacheSortingCriteria === 'priceAscending'
        ? units.sort((current, next) => current.pricePerNight - next.pricePerNight)
        : units.sort((current, next) => next.pricePerNight - current.pricePerNight),
    [cacheSortingCriteria, units]
  );

  return { isLoadingUnits, units: sortedUnits, totalNumberOfUnits, unitRoomType, property, totalNumberOfRooms };
};

function groupByKey(array, key) {
  return array.reduce((hash, obj) => {
    if (obj.roomType._id === undefined) return hash;
    return Object.assign(hash, { [obj.roomType._id]: (hash[obj.roomType._id] || []).concat(obj) });
  }, {});
}

const UnitListing = ({ addUnitBooking, checkIsUnitInBooking, searchAndFilter, updateSearchAndFilter }) => {
  const { hostId } = useParams();
  const { host } = useFetchHost();

  const {
    state: cacheState,
    checkInDate: cacheCheckInDate,
    checkOutDate: cacheCheckOutDate,
    adultPax: cacheAdultPax,
    childPax: cacheChildPax,
    propertyId: cachePropertyId,
    roomTypeId: cacheRoomTypeId,
    roomViews: cacheRoomViews,
    sortingCriteria: cacheSortingCriteria
  } = searchAndFilter;

  const [roomTypePropertyId, setRoomTypePropertyId] = useState(cachePropertyId);

  /* ---------------------------------------------fetching data details--------------------------------------------- */
  const { isLoadingConstants, stateMYSelection, roomViewsSelection } = useFetchConstants();
  const { propertySelection } = useFetchProperties(hostId, host.allowBookingEngine);
  const { roomTypeSelection } = useFetchRoomTypes(hostId, roomTypePropertyId, propertySelection, cacheState, cacheCheckInDate, cacheCheckOutDate);
  const { isLoadingUnits, units, totalNumberOfUnits, unitRoomType, property, totalNumberOfRooms } = useFetchUnits(
    hostId,
    cacheState,
    cacheCheckInDate,
    cacheCheckOutDate,
    cacheAdultPax,
    cacheChildPax,
    cachePropertyId,
    cacheRoomTypeId,
    cacheRoomViews,
    cacheSortingCriteria,
    host.allowHotelBookingEngine
  );

  /* ----------------------------------------------isLoading--------------------------------------------- */
  const isLoading = useMemo(() => isLoadingConstants, [isLoadingConstants]);

  /* ---------------------------------------------functions--------------------------------------------- */
  const handleOnPropertyChange = (propertyId, shouldFetchUnits = true) => {
    setRoomTypePropertyId(propertyId);

    if (shouldFetchUnits) {
      updateSearchAndFilter({ propertyId: propertyId || null, roomTypeId: null });
    }
  };

  const handleOnRoomTypeChange = roomTypeId => {
    updateSearchAndFilter({ roomTypeId: roomTypeId || null });
  };

  const handleOnRoomViewsChange = roomViews => {
    updateSearchAndFilter({ roomViews: roomViews || null });
  };

  const handleOnSortingCriteriaChange = sortingCriteria => {
    updateSearchAndFilter({ sortingCriteria: sortingCriteria || null });
  };

  const handleOnSearchButtonClick = (state, checkInDate, checkOutDate, adultPax, childPax) => {
    updateSearchAndFilter({
      state: state || null,
      checkInDate: checkInDate || null,
      checkOutDate: checkOutDate || null,
      adultPax,
      childPax
    });
  };

  const handleOnFilterModalConfirm = (propertyId, roomTypeId, roomViews, sortingCriteria) => {
    updateSearchAndFilter({
      propertyId: propertyId || null,
      roomTypeId: roomTypeId || null,
      roomViews: roomViews || null,
      sortingCriteria
    });
  };

  const handleOnClickAddUnit = unit => () => {
    const {
      _id,
      image,
      name,
      pricePerNight,
      extraCharges,
      roomType: {
        capacity: { adult, children },
        property: { city, state }
      }
    } = unit;

    const unitState = stateMYSelection.find(stateConstant => stateConstant.value === state).label;

    const formattedUnit = {
      _id,
      adultCapacity: adult,
      childCapacity: children,
      city,
      image,
      name,
      pricePerNight,
      extraCharges,
      state: unitState
    };

    addUnitBooking({ startDate: formatToDateString(cacheCheckInDate), endDate: formatToDateString(cacheCheckOutDate), unit: formattedUnit });
  };

  const handleOnClickAddMultipleUnits = async (roomTypeId, noOfUnits, adultPax, childPax, rtImage) => {
    let units = [];
    const roomTypes = unitRoomType;
    for (let i = 0; i < unitRoomType.length; i++) {
      if (roomTypes[i][0] === roomTypeId) {
        for (let j = 0; j < noOfUnits; j++) {
          let unit;
          unit = roomTypes[i][1][j];

          const {
            _id,
            image,
            name,
            pricePerNight,
            extraCharges,
            roomType: {
              property: { city, state },
              bookingEngine
            }
          } = unit;

          const unitState = stateMYSelection.find(stateConstant => stateConstant.value === state).label;

          const formattedUnit = {
            _id,
            adultCapacity: adultPax,
            childCapacity: childPax,
            city,
            image: rtImage ?? image,
            name,
            pricePerNight,
            extraCharges,
            roomTypeCleaningFee: guard(() => bookingEngine.cleaningFee, 0),
            state: unitState,
            roomTypeId: roomTypeId,
            roomTypeName: guard(() => bookingEngine.externalDisplayName, unit.roomType.name),
            extraGuest: guard(() => bookingEngine.extraGuest, { amount: 0, number: 0 })
          };

          units = [...units, formattedUnit];
        }
      }
    }
    addUnitBooking(
      { startDate: formatToDateString(cacheCheckInDate), endDate: formatToDateString(cacheCheckOutDate), unit: units[0], units: units },
      true
    );
  };
  if (host.allowBookingEngine) {
    return (
      <>
        <Helmet title="Book your stay from your favourite host" />
        <StyledHeader loading={isLoading} active>
          <Header
            stateMYSelection={stateMYSelection}
            roomViewsSelection={roomViewsSelection}
            sortingCriteriaSelection={SORTING_CRITERIAS}
            propertySelection={propertySelection}
            roomTypeSelection={roomTypeSelection}
            state={cacheState}
            checkInDate={cacheCheckInDate}
            checkOutDate={cacheCheckOutDate}
            adultPax={cacheAdultPax}
            childPax={cacheChildPax}
            propertyId={cachePropertyId}
            roomTypeId={cacheRoomTypeId}
            roomViews={cacheRoomViews}
            sortingCriteria={cacheSortingCriteria}
            totalNumberOfUnits={totalNumberOfUnits}
            onPropertyChange={handleOnPropertyChange}
            onRoomTypeChange={handleOnRoomTypeChange}
            onRoomViewsChange={handleOnRoomViewsChange}
            onSortingCriteriachange={handleOnSortingCriteriaChange}
            onSearchButtonClick={handleOnSearchButtonClick}
            onFilterModalConfirm={handleOnFilterModalConfirm}
            host={host}
          />
        </StyledHeader>
        <StyledBody loading={isLoadingUnits} active>
          <Body
            stateMYSelection={stateMYSelection}
            hostId={hostId}
            units={units}
            checkIsUnitInBooking={checkIsUnitInBooking}
            onClickAddUnit={handleOnClickAddUnit}
          />
        </StyledBody>
      </>
    );
  } else {
    return (
      <>
        <Helmet title="Book your stay from your favourite hotel" />
        <StyledHeader loading={isLoading} active>
          <Header
            stateMYSelection={stateMYSelection}
            roomViewsSelection={roomViewsSelection}
            sortingCriteriaSelection={SORTING_CRITERIAS}
            propertySelection={propertySelection}
            roomTypeSelection={roomTypeSelection}
            state={cacheState}
            checkInDate={cacheCheckInDate}
            checkOutDate={cacheCheckOutDate}
            adultPax={cacheAdultPax}
            childPax={cacheChildPax}
            propertyId={cachePropertyId}
            roomTypeId={cacheRoomTypeId}
            roomViews={cacheRoomViews}
            sortingCriteria={cacheSortingCriteria}
            totalNumberOfUnits={totalNumberOfUnits}
            totalNumberOfRooms={totalNumberOfRooms}
            onPropertyChange={handleOnPropertyChange}
            onRoomTypeChange={handleOnRoomTypeChange}
            onRoomViewsChange={handleOnRoomViewsChange}
            onSortingCriteriachange={handleOnSortingCriteriaChange}
            onSearchButtonClick={handleOnSearchButtonClick}
            onFilterModalConfirm={handleOnFilterModalConfirm}
            host={host}
          />
        </StyledHeader>
        <StyledBody loading={isLoadingUnits} active>
          <HotelBody
            stateMYSelection={stateMYSelection}
            hostId={hostId}
            roomType={unitRoomType}
            units={units}
            property={property}
            checkIsUnitInBooking={checkIsUnitInBooking}
            onClickAddUnit={handleOnClickAddUnit}
            onClickAddMultipleUnits={handleOnClickAddMultipleUnits}
            checkInDate={cacheCheckInDate}
            checkOutDate={cacheCheckOutDate}
            pax={cacheAdultPax}
          />
        </StyledBody>
      </>
    );
  }
};

export default withUserContext(UnitListing);
