import React from 'react';
import { Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import Filters from '../Filters/Filters';

import { StyledButton } from './FilterModal.styles';

const FilterModal = ({
  form,
  roomViewsSelection,
  sortingCriteriaSelection,
  propertySelection,
  roomTypeSelection,
  propertyId,
  roomTypeId,
  roomViews,
  sortingCriteria,
  onPropertyChange,
  onFilterModalConfirm,
  onFilterModalClose
}) => {
  const handleOnConfirmButtonClick = e => {
    e.preventDefault();
    form
      .validateFields()
      .then(values => {
        const propertyId = values.property;
        const roomTypeId = values.roomType;
        const roomViews = values.roomViews;
        const sortingCriteria = values.sortingCriteria;

        onFilterModalConfirm(propertyId, roomTypeId, roomViews, sortingCriteria);
        onFilterModalClose();
      })
      .catch(() => {});
  };

  const handleOnFilterModalClose = () => {
    onFilterModalClose();
  };

  return (
    <Modal
      title="Filters"
      visible={true}
      destroyOnClose
      footer={[<StyledButton key="submit" type="primary" icon={<SearchOutlined />} onClick={handleOnConfirmButtonClick} />, null]}
      onCancel={handleOnFilterModalClose}
    >
      <Filters
        form={form}
        roomViewsSelection={roomViewsSelection}
        sortingCriteriaSelection={sortingCriteriaSelection}
        propertySelection={propertySelection}
        roomTypeSelection={roomTypeSelection}
        propertyId={propertyId}
        roomTypeId={roomTypeId}
        roomViews={roomViews}
        sortingCriteria={sortingCriteria}
        onPropertyChange={onPropertyChange}
      />
    </Modal>
  );
};

export default FilterModal;
