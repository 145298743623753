import styled from '@emotion/styled';
import { Button, Card, Row, Col } from 'antd';
import { CloseOutlined, FormOutlined, LeftOutlined, MinusSquareOutlined } from '@ant-design/icons';

export const BackButton = styled(Button)`
  color: ${props => props.theme.color.text};
  font-size: 16px;
  border: none !important;
  height: auto;
  margin-bottom: 16px;
  padding: 4px 0px;
`;

export const BackIcon = styled(LeftOutlined)`
  font-size: 24px;
  margin-right: 12px;
`;

export const DatesRow = styled(Row)`
  padding: 12px;
`;

export const BoldRow = styled(Row)`
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BookingCard = styled(Card)`
  margin: 12px 24px;
`;

export const StyledBookingRow = styled(Row)`
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 0.5px solid #f0f0f0;
`;

export const PriceRow = styled(Row)`
  font-weight: bold;
  margin: 8px 0px;
`;

export const ButtonCol = styled(Col)`
  cursor: pointer;
`;

export const MinusIcon = styled(MinusSquareOutlined)`
  font-size: 16px;
  margin-right: 4px;
`;

export const RemoveIcon = styled(CloseOutlined)`
  font-size: 16px;
  margin-right: 4px;
`;

export const RemarksIcon = styled(FormOutlined)`
  font-size: 16px;
  margin-right: 4px;
`;

export const ImageCol = styled(Col)`
  display: flex;
  align-items: center;
  height: 15vh;
  overflow: hidden;
`;

export const StyledBookingImage = styled.img`
  max-width: 100%;
`;

export const TotalPriceRow = styled(Row)`
  margin: 16px 0 16px 0;
`;

export const TotalRow = styled(Col)`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 24px;
`;

export const CheckBoxRow = styled(Row)`
  margin: 5px 0 0 0;
`;

export const PromotionCard = styled(Card)`
  margin: 16px 0 16px 0;
`;

export const ConfirmBookingButton = styled(Button)`
  width: 100%;
  height: auto;
  text-transform: uppercase;
  margin: 8px 0 8px 0;
`;

export const PriceDetailsContainer = styled.span`
  margin: 0 12px;
`;

export const PriceDetailPopOverContentContainer = styled(Row)`
  width: 280px;
`;

export const PriceDetailContentRow = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

export const StyledTextTitle = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.color.text};
`;

export const StyledTextRules = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.color.text};
`;
