import React from 'react';
import { Col } from 'antd';

import {
  CardTitle,
  ContainerRow,
  RoomDetailsRow,
  SleepingArrangementContainer,
  SleepingArrangementNameRow,
  StyledCard,
  RoomDetailsContainer
} from './RoomInfo.styles';
import { AlreadyBookedIcon } from '../../HotelBody.styles';

const SleepingArrangementRow = ({ sleepingArrangement }) => {
  const { beds, name } = sleepingArrangement;

  return (
    <SleepingArrangementContainer>
      <SleepingArrangementNameRow>{name}</SleepingArrangementNameRow>
      {Array.isArray(beds) && beds.map(bed => <RoomDetailsRow key={`${name} ${bed}`}>{bed}</RoomDetailsRow>)}
    </SleepingArrangementContainer>
  );
};

const AmenityRow = ({ key, amenities }) => {
  return (
    <RoomDetailsContainer>
      {Array.isArray(amenities) &&
        amenities.map((amenity, index) => (
          <RoomDetailsRow key={`${index} ${amenity}`}>
            <AlreadyBookedIcon /> <span style={{ flex: 1, flexWrap: 'wrap' }}>{`${amenity}`}</span>
          </RoomDetailsRow>
        ))}
    </RoomDetailsContainer>
  );
};

export const ReadMore = ({ amenityList }) => {
  let spliceValue = Math.ceil(amenityList.length / 3);
  return (
    <ContainerRow>
      <Col span={24} md={8}>
        <AmenityRow amenities={amenityList.slice(0, spliceValue)} />
      </Col>
      <Col span={24} md={8}>
        <AmenityRow amenities={amenityList.slice(spliceValue, spliceValue * 2)} />
      </Col>
      <Col span={24} md={8}>
        <AmenityRow amenities={amenityList.slice(spliceValue * 2)} />
      </Col>
    </ContainerRow>
  );
};

const RoomInfo = ({ amenities, sleepingArrangements }) => {
  if (Array.isArray(amenities)) {
    return (
      <ContainerRow gutter={{ lg: 16 }}>
        <Col span={24} md={8}>
          <StyledCard title={<CardTitle>Sleeping Arrangements</CardTitle>}>
            {Array.isArray(sleepingArrangements) &&
              sleepingArrangements.map(sleepingArrangement => (
                <SleepingArrangementRow key={sleepingArrangement.name} sleepingArrangement={sleepingArrangement} />
              ))}
          </StyledCard>
        </Col>
        <Col span={24} md={16}>
          <StyledCard title={<CardTitle>Amenities</CardTitle>}>
            <ReadMore amenityList={amenities} />
          </StyledCard>
        </Col>
      </ContainerRow>
    );
  } else {
    return (
      <ContainerRow gutter={{ lg: 16 }}>
        <Col span={24} md={8}>
          <StyledCard title={<CardTitle>Sleeping Arrangements</CardTitle>}>
            {Array.isArray(sleepingArrangements) &&
              sleepingArrangements.map(sleepingArrangement => (
                <SleepingArrangementRow key={sleepingArrangement.name} sleepingArrangement={sleepingArrangement} />
              ))}
          </StyledCard>
        </Col>
        <Col span={24} md={16}>
          <StyledCard title={<CardTitle>Amenities</CardTitle>}>
            <RoomDetailsRow>{`No amenities found`}</RoomDetailsRow>
          </StyledCard>
        </Col>
      </ContainerRow>
    );
  }
};

export default RoomInfo;
