import React from 'react';

import SearchBar from './SearchBar/SearchBar';
import FilterBar from './FilterBar/FilterBar';

const Header = ({
  stateMYSelection,
  roomViewsSelection,
  sortingCriteriaSelection,
  propertySelection,
  roomTypeSelection,
  state,
  checkInDate,
  checkOutDate,
  adultPax,
  childPax,
  propertyId,
  roomTypeId,
  roomViews,
  sortingCriteria,
  totalNumberOfUnits,
  totalNumberOfRooms,
  onPropertyChange,
  onRoomTypeChange,
  onRoomViewsChange,
  onSortingCriteriachange,
  onSearchButtonClick,
  onFilterModalConfirm,
  host,
  homepage = false
}) => {
  return (
    <>
      <SearchBar
        stateMYSelection={stateMYSelection}
        state={state}
        checkInDate={checkInDate}
        checkOutDate={checkOutDate}
        adultPax={adultPax}
        childPax={childPax}
        onSearchButtonClick={onSearchButtonClick}
        host={host}
      />
      <FilterBar
        roomViewsSelection={roomViewsSelection}
        sortingCriteriaSelection={sortingCriteriaSelection}
        propertySelection={propertySelection}
        roomTypeSelection={roomTypeSelection}
        propertyId={propertyId}
        roomTypeId={roomTypeId}
        roomViews={roomViews}
        sortingCriteria={sortingCriteria}
        totalNumberOfUnits={totalNumberOfUnits}
        totalNumberOfRooms={totalNumberOfRooms}
        onPropertyChange={onPropertyChange}
        onRoomTypeChange={onRoomTypeChange}
        onRoomViewsChange={onRoomViewsChange}
        onSortingCriteriachange={onSortingCriteriachange}
        onFilterModalConfirm={onFilterModalConfirm}
        host={host}
        homepage={homepage}
      />
    </>
  );
};

export default Header;
