import styled from '@emotion/styled';
import { Button, Row } from 'antd';
import img from './images/backgroundImage.jpg';

export const SearchBackground = styled.div`
  height: 100%;
  margin: 0 -9.5%;
  padding: 16% 8%;
  background-size: cover;
  background-image: url(${props => (props.theme.background === '' ? img : props.theme.background)});

  @media (min-width: 768px) {
    background-size: cover;
    padding: 6% 4%;
    background-position: 100% 75%;
  }
`;

export const SearchContainer = styled(Row)`
  height: 100%;

  @media (min-width: 768px) {
    height: 40px !important;
  }
`;

export const StyledButton = styled(Button)`
  width: 100% !important;
  height: 40px;
`;
