import { Row } from 'antd';
import styled from '@emotion/styled';

export const TitleRow = styled(Row)`
  color: ${props => props.theme.color.text};
  font-size: 32px;
  line-height: 40px;
`;

export const DescriptionRow = styled(Row)`
  color: ${props => props.theme.color.text};
  font-size: 16px;
  line-height: 32px;
  margin-top: 16px;
`;
