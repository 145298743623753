import React from 'react';
import { Col } from 'antd';

import { CardTitle, ContainerRow, RoomDetailsRow, SleepingArrangementContainer, SleepingArrangementNameRow, StyledCard } from './RoomInfo.styles';

const SleepingArrangementRow = ({ sleepingArrangement }) => {
  const { beds, name } = sleepingArrangement;

  return (
    <SleepingArrangementContainer>
      <SleepingArrangementNameRow>{name}</SleepingArrangementNameRow>
      {Array.isArray(beds) && beds.map(bed => <RoomDetailsRow key={`${name} ${bed}`}>{bed}</RoomDetailsRow>)}
    </SleepingArrangementContainer>
  );
};

const RoomInfo = ({ amenities, sleepingArrangements }) => {
  return (
    <ContainerRow gutter={{ lg: 16 }}>
      <Col span={24} lg={12}>
        <StyledCard title={<CardTitle>Sleeping Arrangements</CardTitle>}>
          {Array.isArray(sleepingArrangements) &&
            sleepingArrangements.map(sleepingArrangement => (
              <SleepingArrangementRow key={sleepingArrangement.name} sleepingArrangement={sleepingArrangement} />
            ))}
        </StyledCard>
      </Col>
      <Col span={24} lg={12}>
        <StyledCard title={<CardTitle>Amenities</CardTitle>}>
          <RoomDetailsRow>{amenities}</RoomDetailsRow>
        </StyledCard>
      </Col>
    </ContainerRow>
  );
};

export default RoomInfo;
