import React, { useState, useMemo, useEffect } from 'react';
import { Card, Row, Col, message, Input, Popover, Checkbox, Alert, Button, Form } from 'antd';
import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { useHistory, useParams, Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_KEY } from 'config/env';

import { getDifferenceBetweenDate, formatToDateString } from 'utils/date';
import { constructDisplayPrice, getSingularOrPluralLabel, guard } from 'utils/general';
import { build404Uri, buildThankYouUri, buildPropertyBaseUri } from 'utils/routes';

import { getBookingTotalPriceAfterPromotion, checkAvailabilityAndPricing, postCreateBooking } from 'apis/booking';
import { useGetHostById } from 'apis/host';
import { useGetPropertiesByHost } from 'apis/property';

import Loading from 'components/FullScreenLoading/FullScreenLoading';
import { withUserContext } from 'contexts/UserContext/UserContext';

import GuestDetailsModal from './components/GuestDetailsModal/GuestDetailsModal';
import RemarksModal from './components/RemarksModal/RemarksModal';
import FormInput from 'components/Input/FormInput/FormInput';
import FormInputNumber from 'components/InputNumber/FormInputNumber/FormInputNumber';
import FormLabel from 'components/FormLabel/FormLabel';
import Helmet from 'components/Helmet/Helmet';

import { LABEL_SIZE_LARGE, REGEX_NOT_NUMBER_N_DOT } from 'utils/constants';
import { LETTER_N_NUMBER } from 'utils/regex';

import NoImagePlaceholder from 'images/no-image-placeholder.jpg';

import {
  BoldRow,
  ConfirmBookingButton,
  DatesRow,
  ImageCol,
  PriceDetailsContainer,
  PriceDetailPopOverContentContainer,
  PriceDetailContentRow,
  PriceRow,
  StyledBookingRow,
  StyledBookingImage,
  StyledTextTitle,
  StyledTextRules,
  TotalPriceRow,
  TotalRow,
  CheckBoxRow,
  PromotionCard,
  BackButton
} from './CheckOut.styles';
const moment = require('moment');

const { TextArea } = Input;
const TIMEOUT_TIME_MS = 300000;

/* =============================================== Local Functions =============================================== */

const submitBooking = (hostId, userBooking, guestDetails, paymentMethod, hCaptchaToken, promotionCode, addOnObj, serviceFee) => {
  const { startDate, endDate, units } = userBooking;
  return postCreateBooking(hostId, {
    hCaptchaToken,
    startDate,
    endDate,
    guestDetails,
    units,
    paymentMethod,
    promotionCode,
    addOnObj,
    serviceFee
  });
};

const UnitPriceDetails = ({ totalRoomRate, extraCharges }) => {
  return (
    <>
      <PriceDetailContentRow span={24}>
        <span>Room Price</span>
        <span>{constructDisplayPrice(totalRoomRate)}</span>
      </PriceDetailContentRow>
      {!isNaN(extraCharges.cleaningFee) && (
        <PriceDetailContentRow span={24}>
          <span>Cleaning Fee</span>
          <span>{constructDisplayPrice(extraCharges.cleaningFee)}</span>
        </PriceDetailContentRow>
      )}
      <PriceDetailContentRow span={24}>
        <span>Room Price</span>
        <span>{constructDisplayPrice(totalRoomRate)}</span>
      </PriceDetailContentRow>
    </>
  );
};

const RoomTypePriceDetails = ({ roomPrice, count, extraCharges, extraGuestCharges }) => {
  return (
    <>
      <PriceDetailContentRow span={24}>
        <span>Room Price</span>
        <span>{constructDisplayPrice(roomPrice)}</span>
      </PriceDetailContentRow>
      {!isNaN(extraCharges) && (
        <PriceDetailContentRow span={24}>
          <span>Cleaning Fee</span>
          <span>{constructDisplayPrice(extraCharges)}</span>
        </PriceDetailContentRow>
      )}
      <PriceDetailContentRow span={24}>
        <span>Number of rooms</span>
        <span>{count}</span>
      </PriceDetailContentRow>
      {extraGuestCharges !== 0 && (
        <PriceDetailContentRow span={24}>
          <span>Extra Guest Fee</span>
          <span>{constructDisplayPrice(extraGuestCharges)}</span>
        </PriceDetailContentRow>
      )}
    </>
  );
};

const TotalPriceDetails = ({
  totalRoomRate,
  totalExtraCharges,
  totalDiscountAmount,
  totalAmount,
  isShowAddOn,
  addOnAmount,
  serviceFee,
  setServiceFee,
  serviceAmount
}) => {
  setServiceFee(serviceFee);
  return (
    <>
      <PriceDetailContentRow span={24}>
        <span>Room Price</span>
        <span>{constructDisplayPrice(totalRoomRate)}</span>
      </PriceDetailContentRow>
      {!isNaN(totalExtraCharges.extraGuestFee) && (
        <PriceDetailContentRow span={24}>
          <span>Extra Guest Fee</span>
          <span>{constructDisplayPrice(totalExtraCharges.extraGuestFee)}</span>
        </PriceDetailContentRow>
      )}
      {!isNaN(totalExtraCharges.cleaningFee) && (
        <PriceDetailContentRow span={24}>
          <span>Cleaning Fee</span>
          <span>{constructDisplayPrice(totalExtraCharges.cleaningFee)}</span>
        </PriceDetailContentRow>
      )}
      {/* <AddOnPrice data={form.getFieldsValue()}></AddOnPrice> */}
      {isShowAddOn && (
        <PriceDetailContentRow span={24}>
          <span>Add-ons</span>
          <span>{constructDisplayPrice(addOnAmount)}</span>
        </PriceDetailContentRow>
      )}
      {!!serviceFee && (
        <PriceDetailContentRow span={24}>
          <span>{`Service Fee (${serviceFee}%)`}</span>
          <span>{constructDisplayPrice(serviceAmount)}</span>
        </PriceDetailContentRow>
      )}
      {!!totalDiscountAmount && (
        <PriceDetailContentRow span={24}>
          <span>Discount</span>
          <span>-({constructDisplayPrice(totalDiscountAmount)})</span>
        </PriceDetailContentRow>
      )}
      <TotalRow span={24}>
        <span>Total</span>
        <span>{constructDisplayPrice(totalAmount)}</span>
      </TotalRow>
    </>
  );
};

const BookingRow = ({ unit, host }) => {
  let {
    adultCapacity,
    childCapacity,
    city,
    state,
    image,
    name,
    extraCharges,
    totalRoomRate,
    total,
    roomTypeName,
    count,
    roomPrice,
    roomTypeCleaningFee
  } = unit;
  const totalExtraGuestFee =
    unit.extraGuest.number !== 0 && adultCapacity > unit.extraGuest.number ? (adultCapacity - unit.extraGuest.number) * unit.extraGuest.amount : 0;
  const totalPrice = roomTypeCleaningFee
    ? roomTypeCleaningFee === undefined
      ? totalRoomRate + totalExtraGuestFee * count
      : totalRoomRate + (roomTypeCleaningFee + totalExtraGuestFee) * count
    : totalRoomRate + totalExtraGuestFee * count;

  return (
    <StyledBookingRow gutter={12}>
      <Col span={14}>
        {host.allowBookingEngine && <BoldRow>{name}</BoldRow>}
        {host.allowHotelBookingEngine && <BoldRow>{`${roomTypeName} x ${count}`}</BoldRow>}
        <Row>
          {city}, {state}
        </Row>
        <Row>
          {!!adultCapacity && getSingularOrPluralLabel(adultCapacity, 'adult')}
          {!!adultCapacity && !!childCapacity && ', '}
          {!!childCapacity && getSingularOrPluralLabel(childCapacity, 'child', 'children')}
        </Row>
        {host.allowBookingEngine && (
          <PriceRow>
            {constructDisplayPrice(total)}
            <Popover
              placement="right"
              content={
                <PriceDetailPopOverContentContainer>
                  <UnitPriceDetails totalRoomRate={totalRoomRate} extraCharges={extraCharges} />
                </PriceDetailPopOverContentContainer>
              }
              title="Price Details"
            >
              <PriceDetailsContainer>
                <InfoCircleOutlined />
              </PriceDetailsContainer>
            </Popover>
          </PriceRow>
        )}
        {host.allowHotelBookingEngine && (
          <PriceRow>
            {constructDisplayPrice(totalPrice)}
            <Popover
              placement="right"
              content={
                <PriceDetailPopOverContentContainer>
                  <RoomTypePriceDetails
                    roomPrice={roomPrice}
                    count={count}
                    extraCharges={roomTypeCleaningFee}
                    extraGuestCharges={totalExtraGuestFee}
                  />
                </PriceDetailPopOverContentContainer>
              }
              title="Price Details"
            >
              <PriceDetailsContainer>
                <InfoCircleOutlined />
              </PriceDetailsContainer>
            </Popover>
          </PriceRow>
        )}
      </Col>
      <ImageCol span={10}>
        <StyledBookingImage src={image || NoImagePlaceholder} alt={name} />
      </ImageCol>
    </StyledBookingRow>
  );
};

const constructExtraChargesDetails = (extraCharges, extraChargesDetails, roomTypeExtraCharges, extraGuest, pax) => {
  const cleaningFee = extraChargesDetails.cleaningFee + (extraCharges.cleaningFee || 0) + (roomTypeExtraCharges || 0);
  const extraGuestFee =
    extraGuest && extraGuest.number !== 0 && pax > extraGuest.number
      ? (extraChargesDetails.extraGuestFee || 0) + (pax - extraGuest.number) * extraGuest.amount
      : (extraChargesDetails.extraGuestFee || 0) + 0;

  return { cleaningFee, extraGuestFee };
};

// const constructExtraGuestChargesDetails = (extraChargesDetails, extraGuest, pax) => {
//   const extraGuestFee =
//     extraGuest.number !== 0
//       ? (extraChargesDetails.extraGuestFee || 0) + (pax - extraGuest.number) * extraGuest.amount
//       : (extraChargesDetails.extraGuestFee || 0) + 0;
//   const cleaningFee = extraChargesDetails.cleaningFee;

//   return { cleaningFee, extraGuestFee };
// };

const calculateUnitsPriceDetails = units => {
  if (!units) {
    return { totalRoomRate: 0, extraChargesDetails: { cleaningFee: 0 } };
  }

  return units.reduce(
    (result, unit) => {
      result.totalRoomRate = result.totalRoomRate + (unit.totalRoomRate || 0);
      if (unit.roomTypeCleaningFee !== undefined) {
        result.extraChargesDetails = constructExtraChargesDetails(
          0,
          result.extraChargesDetails,
          unit.roomTypeCleaningFee,
          unit.extraGuest,
          unit.adultCapacity
        );
      } else {
        result.extraChargesDetails = constructExtraChargesDetails(unit.extraCharges, result.extraChargesDetails, 0);
      }

      return result;
    },
    { totalRoomRate: 0, extraChargesDetails: { cleaningFee: 0, extraGuestFee: 0 } }
  );
};

const calculateTotalPriceAfterPromotion = async ({
  hostId,
  units,
  startDate,
  endDate,
  promotionCode,
  totalRoomRate,
  extraChargesDetails,
  handleOnAfterValidatePromotion,
  roomType,
  addOnAmount,
  addOnObj,
  serviceFee
}) => {
  const extraGuestFee = extraChargesDetails.extraGuestFee || 0;
  let totalPrice = totalRoomRate + extraChargesDetails.cleaningFee + extraChargesDetails.extraGuestFee;
  let totalServiceAmount = 0;
  let totalDiscountAmount = 0;

  if (addOnAmount !== 0) {
    totalPrice += addOnAmount;
  }

  if (serviceFee !== 0) {
    totalServiceAmount = (totalPrice * serviceFee) / 100;
    totalPrice += totalServiceAmount;
  }

  if (promotionCode) {
    const totalPriceAfterPromotion = await getBookingTotalPriceAfterPromotion(hostId, {
      promotionCode,
      unitIds: units.map(unit => unit._id),
      startDate,
      endDate,
      addOnObj,
      extraGuestFee
    }).catch(ex => handleOnAfterValidatePromotion(false, ex.message));

    if (totalPriceAfterPromotion) {
      handleOnAfterValidatePromotion(true, totalPriceAfterPromotion.invalidDatesArr);

      totalServiceAmount = (totalPriceAfterPromotion.totalAfterPromotion * serviceFee) / 100;
      totalPrice = totalPriceAfterPromotion.totalAfterPromotion + addOnAmount + totalServiceAmount;
      totalDiscountAmount = totalPriceAfterPromotion.finalDiscAmount;
    }
  }

  return { finalPayableAmount: totalPrice, totalDiscountAmount, totalServiceAmount };
};

// const calculateTotalPriceAfterAddOn = async ({
//   addOnObj
// }) => {
//   if (addOnObj)

//   return { finalAddOnAmount };
// };

/* =============================================== Use Function =============================================== */

const usePromotion = form => {
  const [isShowPromotion, setIsShowPromotion] = useState(false);
  const [isConfirmBooking, setIsConfirmBooking] = useState(false);
  const [invalidPromotionCodeMessage, setInvalidPromotionCodeMessage] = useState('');
  const [partialInvalidPromotionCodeMessage, setPartialInvalidPromotionCodeMessage] = useState('');
  const [promotionCode, setPromotionCode] = useState(null);
  const [isShowAddOnPrice, setIsShowAddOnPrice] = useState(false);
  const [addOnObj, setAddOns] = useState([]);
  const [addOnAmount, setAddOnAmount] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);

  const handleOnResetPromotionState = (errorMessage = '') => {
    setInvalidPromotionCodeMessage(errorMessage);
    setPartialInvalidPromotionCodeMessage(errorMessage);
    setPromotionCode('');
  };

  const handleOnClosePromotion = () => {
    setIsShowPromotion(false);
    form.setFieldsValue({
      promotionCode: ''
    });

    handleOnResetPromotionState();
  };

  const handleOnCheckBoxClick = checked => {
    setIsShowPromotion(checked);

    if (!checked) {
      form.setFieldsValue({
        promotionCode: ''
      });

      handleOnResetPromotionState();
    }
  };

  const handleOnHouseRulesCheckBoxClick = checked => {
    setIsConfirmBooking(checked);
  };

  const handleOnChangePromotionCode = promotionCode => {
    if (!!promotionCode) {
      handleOnResetPromotionState();
    } else {
      setPromotionCode('');
    }
  };

  const handleOnApplyPromotionCodeClick = totalRoomRate => async () => {
    const promotionCode = form.getFieldValue('promotionCode');

    setPromotionCode(promotionCode);
  };

  const handleOnAddOnInputChange = (activityName, amount) => e => {
    let displayAmount = 0;
    addOnObj[activityName] = {
      amountPerPax: amount,
      amount: e * amount,
      pax: e
    };

    if (e === 0) {
      addOnObj[activityName] = {};
    }
    const allAddOns =
      addOnObj &&
      Object.keys(addOnObj).map(key => {
        return addOnObj[key] && addOnObj[key].amount > 0 ? addOnObj[key].amount : 0;
      });
    allAddOns.reduce((total, amount) => total + amount, 0);

    if (allAddOns.length > 0) {
      displayAmount = 0;
      allAddOns.forEach(amount => {
        displayAmount += amount;
      });
    }

    if (displayAmount === 0) {
      setIsShowAddOnPrice(false);
    } else {
      setIsShowAddOnPrice(true);
    }

    // console.log(386, allAddOns, displayAmount);
    setAddOnAmount(displayAmount);
    setAddOns(addOnObj);
  };

  const handleOnAfterValidatePromotion = (isValidPromotion, errorMessage = '') => {
    if (isValidPromotion) {
      setInvalidPromotionCodeMessage('');
      if (errorMessage !== '' && errorMessage.length > 0) {
        setPartialInvalidPromotionCodeMessage(`The promotion code you applied is not valid for the dates ${errorMessage}.`);
      }
    } else {
      handleOnResetPromotionState(errorMessage);
    }
  };

  return {
    isConfirmBooking,
    isShowPromotion,
    isShowAddOnPrice,

    invalidPromotionCodeMessage,
    partialInvalidPromotionCodeMessage,
    promotionCode,
    addOnObj,
    addOnAmount,
    serviceFee,

    setAddOnAmount,
    handleOnCheckBoxClick,
    handleOnHouseRulesCheckBoxClick,
    handleOnChangePromotionCode,
    handleOnApplyPromotionCodeClick,
    handleOnClosePromotion,
    handleOnAfterValidatePromotion,
    handleOnAddOnInputChange,
    setServiceFee
  };
};

const useCalculateTotalPriceDetails = (
  hostId,
  units,
  startDate,
  endDate,
  promotionCode,
  handleOnAfterValidatePromotion,
  roomType,
  addOnAmount,
  addOnObj,
  serviceFee
) => {
  const [finalPayableAmount, setFinalPayableAmount] = useState(0);
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const [totalServiceAmount, setTotalServiceAmount] = useState(0);

  const { totalRoomRate, extraChargesDetails } = useMemo(() => calculateUnitsPriceDetails(units), [units]);
  useEffect(() => {
    calculateTotalPriceAfterPromotion({
      hostId,
      units,
      startDate,
      endDate,
      promotionCode,
      totalRoomRate,
      extraChargesDetails,
      handleOnAfterValidatePromotion,
      roomType,
      addOnAmount,
      addOnObj,
      serviceFee
    }).then(({ finalPayableAmount, totalDiscountAmount, totalServiceAmount }) => {
      setFinalPayableAmount(finalPayableAmount);
      setTotalDiscountAmount(totalDiscountAmount);
      setTotalServiceAmount(totalServiceAmount);
    });
  }, [
    hostId,
    units,
    startDate,
    endDate,
    promotionCode,
    totalRoomRate,
    extraChargesDetails,
    handleOnAfterValidatePromotion,
    roomType,
    addOnAmount,
    addOnObj,
    serviceFee
  ]);

  return {
    total: finalPayableAmount,
    totalRoomRate,
    totalExtraCharges: extraChargesDetails,
    totalDiscountAmount: totalDiscountAmount,
    totalServiceAmount: totalServiceAmount
  };
};

const useFetchHost = () => {
  const { hostId } = useParams();

  const {
    isFetching,
    data: { host },
    error
  } = useGetHostById(hostId);

  const localHost = useMemo(() => guard(() => host, {}), [host]);
  const hostStripeConnectId = useMemo(() => guard(() => host.bookingEngine.paymentInfo.stripeConnectAccountId), [host]);

  return { isFetching, host: localHost, hostStripeConnectId: hostStripeConnectId, error };
};

const useFetchProperties = () => {
  const { hostId } = useParams();

  const {
    data: { properties }
  } = useGetPropertiesByHost(hostId, { fields: ['_id', 'addOns', 'bookingEngine'] });
  return { properties };
};
/* =============================================== Main Component =============================================== */

const CheckOut = ({ clearBooking, userBooking, updateBookingRemarks }) => {
  const history = useHistory();
  const { hostId, propertyId } = useParams();
  const [form] = Form.useForm();
  const { host, hostStripeConnectId } = useFetchHost();
  const { properties } = useFetchProperties();
  const [isConfirmBookingLoading, setIsConfirmBookingLoading] = useState(false);

  const checkStatus = async () => {
    const availability = await checkAvailabilityAndPricing(hostId, {
      units: units.map(unit => unit._id.toString()),
      startDate,
      endDate
    }).catch(ex => handleOnAfterValidatePromotion(false, ex.message));
    // console.log(555, availability);
    if (availability.isUnitAvailable) {
      return;
    } else {
      message.error('Sorry! Room sold out! Please select a new room!', [10]);
      history.replace(buildPropertyBaseUri(hostId, propertyId));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkStatus();
    }, TIMEOUT_TIME_MS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const { startDate, endDate, units } = userBooking;
  let roomType = [];

  const {
    isConfirmBooking,
    isShowPromotion,
    isShowAddOnPrice,

    invalidPromotionCodeMessage,
    partialInvalidPromotionCodeMessage,
    promotionCode,
    addOnObj,
    addOnAmount,
    serviceFee,

    setAddOnAmount,
    handleOnCheckBoxClick,
    handleOnHouseRulesCheckBoxClick,
    handleOnChangePromotionCode,
    handleOnApplyPromotionCodeClick,
    handleOnClosePromotion,
    handleOnAfterValidatePromotion,
    handleOnAddOnInputChange,
    setServiceFee
  } = usePromotion(form);

  // const { addOnObj, handleOnAddOnInputChange } = useAddOn(form);
  let property = [
    {
      addOns: [],
      bookingEngine: {},
      tax: []
    }
  ];

  if (!!properties && properties.length > 0) {
    property = properties.filter(property => property._id === propertyId);
  }

  const { total, totalRoomRate, totalExtraCharges, totalDiscountAmount, totalServiceAmount } = useCalculateTotalPriceDetails(
    hostId,
    units,
    startDate,
    endDate,
    promotionCode,
    handleOnAfterValidatePromotion,
    roomType,
    addOnAmount,
    addOnObj,
    serviceFee
  );

  const bookingNights = useMemo(() => getDifferenceBetweenDate(startDate, endDate), [startDate, endDate]);
  const formattedStartDate = useMemo(() => formatToDateString(startDate, '/'), [startDate]);
  const formattedEndDate = useMemo(() => formatToDateString(endDate, '/'), [endDate]);
  const isSkipStripePayment = useMemo(() => total < 2, [total]);

  const formatAddOnsDetails = formFields => {
    let addOnKeys = [];
    let addOnObj = [];
    if (!!property[0].addOns) {
      property[0].addOns.forEach(addOn =>
        addOnKeys.push({
          activity: addOn.activity,
          amount: addOn.amount
        })
      );
    }

    addOnKeys.forEach(addOn => {
      let key = addOn.activity;
      if (formFields[`${key}`] > 0) {
        addOnObj.push({
          activity: addOn.activity,
          amount: parseFloat(addOn.amount * (formFields[`${key}`] ? formFields[`${key}`] : 0) || 0),
          amountPerPax: parseFloat(addOn.amount || 0),
          pax: formFields[`${key}`] ? formFields[`${key}`] : 0
        });
      }
    });

    return addOnObj;
  };

  const formatGuestDetails = formFields => {
    // console.log(551, formFields);

    return {
      firstName: formFields.firstName,
      lastName: formFields.lastName,
      icNo: formFields.icNo,
      email: formFields.email,
      contactNo: formFields.contactNo
    };
  };

  const handleOnConfirmBookingSubmit = () => {
    const formFields = form.getFieldsValue();
    const addOnPayload = formatAddOnsDetails(formFields);
    const guestDetails = formatGuestDetails(formFields);
    const paymentMethod = formFields.paymentMethod;
    const hCaptchaToken = formFields.hCaptchaToken;
    const promotionCode = formFields.promotionCode;

    updateBookingRemarks(formFields.remarks ? formFields.remarks : '');

    if (
      guestDetails.contactNo === undefined ||
      guestDetails.firstName === undefined ||
      guestDetails.lastName === undefined ||
      guestDetails.icNo === undefined ||
      guestDetails.email === undefined
    ) {
      return;
    }
    setIsConfirmBookingLoading(true);
    submitBooking(hostId, userBooking, guestDetails, paymentMethod, hCaptchaToken, promotionCode, addOnPayload, serviceFee)
      .then(async ({ confirmationCodes, paymentSession }) => {
        clearBooking();
        handleOnClosePromotion();
        setAddOnAmount(0);
        if (!!paymentSession) {
          const stripe = await loadStripe(STRIPE_KEY, {
            stripeAccount: hostStripeConnectId
          });
          stripe
            .redirectToCheckout({
              sessionId: paymentSession.id
            })
            .then(result => {
              if (!!result.error) {
                throw new Error(result.error);
              }
              setIsConfirmBookingLoading(false);
            });
        } else {
          // Stripe payment error if payment lower than RM2
          if (isSkipStripePayment) {
            history.push(`${buildThankYouUri(hostId)}?confirmationCodes=${confirmationCodes[0]}&totalAfterDiscount=${total}`); //confirmationCodes.join(',')
          } else {
            history.push(`${buildThankYouUri(hostId)}?confirmationCodes=${confirmationCodes[0]}`);
          }
        }
      })
      .catch(ex => {
        let errorMessage = 'Something went wrong when creating your booking. Please try again later or contact our support.';

        if (ex.code === 404) {
          errorMessage = 'The host selected does not exist.';
          history.push(build404Uri());
        } else if (ex.code === '40002') {
          clearBooking();
          handleOnClosePromotion();
          errorMessage = 'The start date is set before today, please select a valid date and try again.';
        }

        message.error(errorMessage);
        // message.error('Please refresh our page');
        console.error(ex);
      })
      .finally(() => {
        setIsConfirmBookingLoading(false);
      });
  };

  const handleOnFormSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
      }
    });
  };

  let rt = [];
  if (units !== undefined) {
    for (let i = 0; i < units.length; i++) {
      if (roomType.length === 0) {
        roomType.push(units[i]);
      } else {
        for (let j = 0; j < roomType.length; j++) {
          if (
            roomType.some(element => {
              if (units[i].roomTypeId === element.roomTypeId) return true;
              return false;
            })
          ) {
          } else {
            roomType.push(units[i]);
          }
        }
      }
    }
  }

  for (let num = 0; num < roomType.length; num++) {
    let count = 0;
    if (
      units.every(element => {
        if (roomType[num].roomTypeId === element.roomTypeId) count++;
        return true;
      })
    ) {
      const formattedRT = {
        _id: roomType[num]._id,
        adultCapacity: roomType[num].adultCapacity,
        childCapacity: roomType[num].childCapacity,
        city: roomType[num].city,
        image: roomType[num].image,
        name: roomType[num].name,
        roomPrice: roomType[num].totalRoomRate,
        total: roomType[num].total * count,
        totalRoomRate: roomType[num].totalRoomRate * count,
        extraCharges: roomType[num].extraCharges,
        extraGuest: roomType[num].extraGuest,
        state: roomType[num].state,
        roomTypeId: roomType[num].roomTypeId,
        roomTypeName: roomType[num].roomTypeName,
        roomTypeCleaningFee: roomType[num].roomTypeCleaningFee,
        count: count
      };
      rt.push(formattedRT);
    }
  }
  return (
    <>
      <Helmet title="Book your stay from your favourite hotel" />
      {/* {isConfirmBookingLoading && (<Modal visible={isConfirmBookingLoading} title="Confirm Booking" onOk={() => { }} onCancel={setIsConfirmBookingLoading(false)} destroyOnClose></Modal>)} */}
      <Loading isLoading={isConfirmBookingLoading} />
      <Link to={buildPropertyBaseUri(hostId, propertyId)}>
        <BackButton icon={<LeftOutlined />}>Back to View All Available Rooms</BackButton>
      </Link>
      <Form onSubmit={handleOnFormSubmit} layout="horizontal" form={form}>
        <Row gutter={16}>
          <Col span={24} lg={16} style={{ marginBottom: '16px' }}>
            <div style={{ marginBottom: '16px' }}>
              <GuestDetailsModal
                // onSubmit={handleOnConfirmBookingSubmit}
                form={form}
                hostStripeConnectId={hostStripeConnectId}
                isSkipStripePayment={isSkipStripePayment}
              />
            </div>
            {!!property && property[0].addOns && property[0].addOns.length > 0 && (
              <div style={{ marginBottom: '16px' }}>
                <Card title={<StyledTextTitle>Add-ons</StyledTextTitle>}>
                  {property[0].addOns.map(addOn => (
                    <Row key={addOn.activity} justify="start" gutter={8}>
                      <Col span={16}>
                        <FormLabel labelSize={LABEL_SIZE_LARGE} style={{ marginTop: '8px' }}>
                          {addOn.activity}
                        </FormLabel>
                      </Col>
                      <Col span={3}>
                        <FormLabel labelSize={LABEL_SIZE_LARGE} style={{ marginTop: '8px' }}>
                          RM{addOn.amount}{' '}
                        </FormLabel>
                      </Col>
                      <Col span={1}>
                        <FormLabel labelSize={LABEL_SIZE_LARGE} style={{ marginTop: '8px' }}>
                          {' '}
                          x{' '}
                        </FormLabel>
                      </Col>
                      <Col span={3}>
                        <FormInputNumber
                          form={form}
                          name={addOn.activity}
                          placeholder={'0'}
                          minValue={0}
                          maxValue={10}
                          parser={value => value.replace(REGEX_NOT_NUMBER_N_DOT, '')}
                          onChange={handleOnAddOnInputChange(addOn.activity, addOn.amount)}
                          // onChange={handlePriceChange()}
                        />
                      </Col>
                      {/* <Col span={1}>
                        <FormLabel labelSize={LABEL_SIZE_LARGE}>Pax</FormLabel>
                      </Col> */}
                    </Row>
                  ))}
                </Card>
              </div>
            )}
            <div style={{ marginBottom: '16px' }}>
              <RemarksModal form={form} />
            </div>
            <div style={{ marginBottom: '16px' }}>
              <Card title={<StyledTextTitle>House Rules</StyledTextTitle>}>
                {!!property && property[0].bookingEngine && (
                  <Col justify="start" gutter={8}>
                    <Row style={{ padding: 5 }}>
                      <FormLabel labelSize={LABEL_SIZE_LARGE}>
                        {<StyledTextRules>{property[0].bookingEngine.nonSmoking ? 'No Smoking Allowed' : 'Smoking Allowed'}</StyledTextRules>}
                      </FormLabel>
                    </Row>
                    {property[0].bookingEngine.cashDeposit && (
                      <Row style={{ padding: 5 }}>
                        <FormLabel labelSize={LABEL_SIZE_LARGE}>
                          {
                            <StyledTextRules>
                              {property[0].bookingEngine.cashDeposit
                                ? 'Security Deposit collect in cash upon check in'
                                : 'No security deposit needed'}
                            </StyledTextRules>
                          }
                        </FormLabel>
                      </Row>
                    )}
                    {property[0].bookingEngine.bankDeposit && (
                      <Row style={{ padding: 5 }}>
                        <FormLabel labelSize={LABEL_SIZE_LARGE}>
                          {
                            <StyledTextRules>
                              {property[0].bookingEngine.bankDeposit ? 'Security Deposit collect via bank transfer' : 'No security deposit needed'}
                            </StyledTextRules>
                          }
                        </FormLabel>
                      </Row>
                    )}
                    <Row style={{ padding: 5 }}>
                      <TextArea rows={4} value={property[0].bookingEngine.houseRules} disabled={true} />
                    </Row>
                  </Col>
                )}
              </Card>
            </div>
          </Col>
          <Col span={24} lg={8} style={{ marginBottom: '16px' }}>
            <Card title={<StyledTextTitle>My Booking Summary</StyledTextTitle>}>
              <DatesRow gutter={16}>
                {/* <Row span={24}> */}
                <Col span={16}>
                  <BoldRow>Dates</BoldRow>
                  {formattedStartDate} - {formattedEndDate}
                </Col>
                <Col span={8}>
                  <BoldRow>Nights</BoldRow>
                  {bookingNights}
                </Col>
                {/* </Row> */}
              </DatesRow>
              <DatesRow>
                {host.allowBookingEngine && units.map((unit, index) => <BookingRow key={unit._id} unit={unit} host={host} />)}
                {host.allowHotelBookingEngine && rt.map((room, index) => <BookingRow key={room._id} unit={room} host={host} addOnObj={addOnObj} />)}
                <TotalPriceRow>
                  <TotalPriceDetails
                    totalRoomRate={totalRoomRate}
                    totalExtraCharges={totalExtraCharges}
                    totalDiscountAmount={totalDiscountAmount}
                    totalAmount={total}
                    isShowAddOn={isShowAddOnPrice}
                    addOnAmount={addOnAmount}
                    serviceFee={property[0].bookingEngine.serviceFee ? property[0].bookingEngine.serviceFee : 0}
                    setServiceFee={setServiceFee}
                    serviceAmount={totalServiceAmount}
                    // extraGuest={extraGuest}
                  />
                </TotalPriceRow>
                <CheckBoxRow>
                  <Checkbox onChange={e => handleOnCheckBoxClick(e.target.checked)}>Apply promotion code?</Checkbox>
                </CheckBoxRow>
                {isShowPromotion && (
                  <PromotionCard>
                    <Row gutter={[0, 8]}>
                      <Col span={24}>
                        <FormLabel labelSize={LABEL_SIZE_LARGE}>Promotion Code</FormLabel>
                      </Col>
                      {invalidPromotionCodeMessage !== '' && (
                        <Col span={24}>
                          <Alert message={<span>Please apply your promotion code before confirming the booking.</span>} type="warning" showIcon />
                        </Col>
                      )}
                      {invalidPromotionCodeMessage && (
                        <Col span={24}>
                          <Alert message={<span>{invalidPromotionCodeMessage}</span>} type="error" showIcon />
                        </Col>
                      )}
                      {partialInvalidPromotionCodeMessage && (
                        <Col span={24}>
                          <Alert message={<span>{partialInvalidPromotionCodeMessage}</span>} type="warning" showIcon />
                        </Col>
                      )}
                      <Col span={24}>
                        <Form form={form}>
                          <FormInput
                            name="promotionCode"
                            placeholder="Apply your code"
                            onChange={handleOnChangePromotionCode}
                            extraRules={[{ pattern: LETTER_N_NUMBER, message: 'Combination of Numbers and Letters only' }]}
                            uppercase
                          />
                        </Form>
                      </Col>
                      <Col span={24}>
                        <Button type="primary" block onClick={handleOnApplyPromotionCodeClick(totalRoomRate)} disabled={isConfirmBookingLoading}>
                          Apply
                        </Button>
                      </Col>
                    </Row>
                  </PromotionCard>
                )}
                <CheckBoxRow>
                  <Checkbox onChange={e => handleOnHouseRulesCheckBoxClick(e.target.checked)}>By ticking this, I agree to these House rules</Checkbox>
                </CheckBoxRow>
                <ConfirmBookingButton type="primary" onClick={handleOnConfirmBookingSubmit} disabled={!isConfirmBooking} htmlType="submit">
                  Confirm my booking
                </ConfirmBookingButton>
              </DatesRow>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default withUserContext(CheckOut);
