import api from './apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

export const useGetHostById = hostId => {
  const getHostById = (key, hostId) => {
    return api.get(`bookingEngine/${hostId}`);
  };

  return useCustomQuery('host', [hostId], getHostById);
};
