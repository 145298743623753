import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import styled from '@emotion/styled';

export const AddButton = styled(Button)`
  width: 100%;
  height: 40px;
  text-transform: uppercase;
`;

export const AlreadyBookedRow = styled(Row)`
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  background-color: ${props => props.theme.color.success};
  color: white;
`;

export const AlreadyBookedIcon = styled(CheckCircleOutlined)`
  margin-right: 4px;
`;

export const StyledCard = styled(Card)`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const PricePerNightCol = styled(Col)`
  font-size: 24px;
  color: ${props => props.theme.color.text};
`;

export const PricePerNightSpan = styled.span`
  font-weight: bold;
  padding-right: 4px;
`;

export const CleaningFeeCol = styled(Col)`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
`;
