import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

import { withAppContext } from 'contexts/AppContext/AppContext';

import HostPlatformImage from 'images/hostplatform-logo-icon.png';
import { guard } from 'utils/general';

const Helmet = ({ host, title }) => {
  const hostImageURL = guard(() => host.images[0].imageUrl, HostPlatformImage);

  return host && Object.keys(host).length > 0 ? (
    <ReactHelmet>
      <link rel="icon" href={hostImageURL} />
      <title>{`${host.name} | ${title}`}</title>
    </ReactHelmet>
  ) : null;
};

export default withAppContext(Helmet);
