import React, { useCallback, useEffect, useMemo } from 'react';
import { useCookies, CookiesProvider } from 'react-cookie';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { withAppContext } from 'contexts/AppContext/AppContext';
import { SORTING_CRITERIAS } from 'utils/constants';
import { checkIsDate, formatToDateString, formatToMomentObject, getTodayMoment, getTomorrowMoment, getDifferenceBetweenDate } from 'utils/date';
import { checkIsObjectEmpty, guard } from 'utils/general';

const UserContext = React.createContext();

const COOKIE_KEY_BOOKING = 'booking';
const COOKIE_KEY_SEARCH_FLTER = 'search and filter';

const DEFAULT_STATE = undefined;
const DEFAULT_CHECKINDATE = undefined;
const DEFAULT_CHECKOUTDATE = undefined;
const DEFAULT_ADULTPAX = 1;
const DEFAULT_CHILDPAX = 0;
const DEFAULT_PROPERTYID = undefined;
const DEFAULT_ROOMTYPEID = undefined;
const DEFAULT_ROOMVIEWS = [];
const DEFAULT_SORTINGCRITERIA = SORTING_CRITERIAS.find(s => s.isDefault).value;

const DEFAULT_SEARCH_AND_FILTER = {
  state: DEFAULT_STATE,
  checkInDate: DEFAULT_CHECKINDATE,
  checkOutDate: DEFAULT_CHECKOUTDATE,
  adultPax: DEFAULT_ADULTPAX,
  childPax: DEFAULT_CHILDPAX,
  propertyId: DEFAULT_PROPERTYID,
  roomTypeId: DEFAULT_ROOMTYPEID,
  roomViews: DEFAULT_ROOMVIEWS,
  sortingCriteria: DEFAULT_SORTINGCRITERIA
};

const useSearchAndFilter = cookieOptions => {
  const [cookies, setCookie] = useCookies([COOKIE_KEY_SEARCH_FLTER]);

  const searchAndFilter = useMemo(() => {
    if (cookies && cookies[COOKIE_KEY_SEARCH_FLTER]) {
      return cookies[COOKIE_KEY_SEARCH_FLTER];
    }

    return DEFAULT_SEARCH_AND_FILTER;
  }, [cookies]);

  const searchAndFilterForRender = useMemo(() => {
    const checkInDate = formatToMomentObject(searchAndFilter.checkInDate);
    const checkOutDate = formatToMomentObject(searchAndFilter.checkOutDate);

    return { ...searchAndFilter, checkInDate, checkOutDate };
  }, [searchAndFilter]);

  const updateSearchAndFilter = ({
    state,
    checkInDate,
    checkOutDate,
    adultPax,
    childPax,
    propertyId,
    roomTypeId,
    roomViews,
    sortingCriteria,
    reload = true
  }) => {
    const updatedSearchAndFilter = {
      ...searchAndFilter,
      ...(state !== undefined && { state: state !== null ? state : DEFAULT_STATE }),
      ...(checkInDate !== undefined && { checkInDate: checkInDate !== null ? formatToDateString(checkInDate) : DEFAULT_CHECKINDATE }),
      ...(checkOutDate !== undefined && { checkOutDate: checkOutDate !== null ? formatToDateString(checkOutDate) : DEFAULT_CHECKOUTDATE }),
      ...(adultPax !== undefined && { adultPax: adultPax !== null ? adultPax : DEFAULT_ADULTPAX }),
      ...(childPax !== undefined && { childPax: childPax !== null ? childPax : DEFAULT_CHILDPAX }),
      ...(propertyId !== undefined && { propertyId: propertyId !== null ? propertyId : DEFAULT_PROPERTYID }),
      ...(roomTypeId !== undefined && { roomTypeId: roomTypeId !== null ? roomTypeId : DEFAULT_ROOMTYPEID }),
      ...(roomViews !== undefined && { roomViews: roomViews !== null ? roomViews : DEFAULT_ROOMVIEWS }),
      ...(sortingCriteria !== undefined && { sortingCriteria: sortingCriteria !== null ? sortingCriteria : DEFAULT_SORTINGCRITERIA })
    };

    setCookie(COOKIE_KEY_SEARCH_FLTER, updatedSearchAndFilter, cookieOptions);
    if (reload) {
      window.location.reload();
    }
  };

  return { searchAndFilter: searchAndFilterForRender, updateSearchAndFilter };
};

const useUserBooking = (cookieOptions, updateSearchAndFilter) => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_KEY_BOOKING]);

  const userBooking = useMemo(() => guard(() => cookies[COOKIE_KEY_BOOKING], {}), [cookies]);

  /* ----------------------------------------local function-----------------------------------------*/
  const updateBooking = newUserBooking => {
    setCookie(COOKIE_KEY_BOOKING, newUserBooking, cookieOptions);
  };

  const showAddSuccessMessage = () => {
    message.success('Unit added to your booking');
  };

  const createNewBooking = (startDate, endDate, unitBooking) => {
    updateSearchAndFilter({ checkInDate: startDate, checkOutDate: endDate, reload: false });
    const newUserBooking = { startDate, endDate, units: [unitBooking] };
    updateBooking(newUserBooking);
    showAddSuccessMessage();
  };

  const addUnitToExistingBooking = unitBooking => {
    const newUserBooking = { ...userBooking, units: [...userBooking.units, unitBooking] };
    updateBooking(newUserBooking);
    showAddSuccessMessage();
  };

  const createMultipleNewBookings = (startDate, endDate, unitsBooking) => {
    updateSearchAndFilter({ checkInDate: startDate, checkOutDate: endDate, reload: false });
    let newUserBooking = { startDate, endDate, units: [] };
    newUserBooking = { startDate, endDate, units: unitsBooking };
    updateBooking(newUserBooking);
    showAddSuccessMessage();
  };

  const addMultipleUnitToExistingBooking = unitBookings => {
    let newUserBooking = { ...userBooking, units: [...userBooking.units] };
    newUserBooking = { ...userBooking, units: [...userBooking.units.concat(unitBookings)] };
    updateBooking(newUserBooking);
    showAddSuccessMessage();
  };

  const calculateTotalExtraCharges = extraCharges => {
    const cleaningFee = extraCharges.cleaningFee || 0;

    return cleaningFee;
  };

  const calculateTotalExtraGuestCharges = (extraGuest, pax) => {
    let extraGuestFee = 0;
    if (extraGuest !== undefined && extraGuest.number !== 0 && pax > extraGuest.number) {
      const extraGuestNumber = pax - extraGuest.number;
      extraGuestFee = extraGuestNumber * extraGuest.amount;
    }

    return extraGuestFee;
  };

  const getNewUnitBookings = (units, newBookingStartDate, newBookingEndDate) => {
    let u = [];
    units.forEach(unit => {
      const {
        _id,
        adultCapacity,
        childCapacity,
        city,
        extraCharges,
        image,
        name,
        pricePerNight,
        state,
        roomTypeId,
        roomTypeName,
        roomTypeCleaningFee,
        extraGuest
      } = unit;

      const bookingNights = getDifferenceBetweenDate(newBookingStartDate, newBookingEndDate);
      const totalRoomRate = bookingNights * pricePerNight;

      const newUnitBooking = {
        _id,
        adultCapacity: adultCapacity,
        childCapacity: childCapacity,
        name,
        city,
        state,
        image,
        extraCharges,
        roomTypeCleaningFee: roomTypeCleaningFee,
        extraGuest: extraGuest,
        totalRoomRate,
        roomTypeId,
        roomTypeName,
        total:
          totalRoomRate +
          calculateTotalExtraCharges(extraCharges) +
          calculateTotalExtraCharges(roomTypeCleaningFee) +
          calculateTotalExtraGuestCharges(extraGuest, adultCapacity)
      };

      u = [...u, newUnitBooking];
    });

    return u;
  };

  /* ----------------------------------------exported function-----------------------------------------*/
  const addUnitBooking = ({ startDate: newBookingStartDate, endDate: newBookingEndDate, unit, units }, multipleUnits = false) => {
    let u = [];
    const currentBookingStartDate = userBooking.startDate;
    const currentBookingEndDate = userBooking.endDate;

    const hasDate = newBookingStartDate && newBookingEndDate;
    const isDateAfterToday = hasDate && checkIsDate('after', newBookingStartDate, getTodayMoment(), true);

    if (!hasDate || !isDateAfterToday) {
      const warningMessage = !hasDate
        ? 'Search with your stay period to get more accurate price deals'
        : 'The date is set in the past, please select a new date range';
      message.warning(warningMessage);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }

    if (units) {
      u = getNewUnitBookings(units, newBookingStartDate, newBookingEndDate);
    }

    const {
      _id,
      adultCapacity,
      childCapacity,
      city,
      extraCharges,
      image,
      name,
      pricePerNight,
      state,
      roomTypeId,
      roomTypeName,
      roomTypeCleaningFee,
      extraGuest
    } = unit;

    const bookingNights = getDifferenceBetweenDate(newBookingStartDate, newBookingEndDate);
    const totalRoomRate = bookingNights * pricePerNight;

    const newUnitBooking = {
      _id,
      adultCapacity: adultCapacity,
      childCapacity: childCapacity,
      name,
      city,
      state,
      image,
      extraCharges,
      roomTypeCleaningFee: roomTypeCleaningFee,
      extraGuest: calculateTotalExtraGuestCharges(extraGuest, adultCapacity),
      totalRoomRate,
      roomTypeId,
      roomTypeName,
      total: multipleUnits
        ? totalRoomRate +
          calculateTotalExtraCharges(extraCharges) +
          calculateTotalExtraCharges(roomTypeCleaningFee) +
          calculateTotalExtraGuestCharges(extraGuest, adultCapacity)
        : totalRoomRate + calculateTotalExtraCharges(extraCharges)
    };

    if (currentBookingStartDate && currentBookingEndDate) {
      if (!checkIsDate('same', currentBookingStartDate, newBookingStartDate) || !checkIsDate('same', currentBookingEndDate, newBookingEndDate)) {
        Modal.confirm({
          title: 'You are about to make booking on another date',
          icon: <ExclamationCircleOutlined />,
          content: 'This will reset all current booking in the cart',
          okText: 'Yes',
          cancelText: 'No, I want to keep my booking',
          onOk() {
            createNewBooking(newBookingStartDate, newBookingEndDate, newUnitBooking);
          }
        });
      } else if (multipleUnits) {
        addMultipleUnitToExistingBooking(u);
      } else {
        addUnitToExistingBooking(newUnitBooking);
      }
    } else if (multipleUnits) {
      createMultipleNewBookings(newBookingStartDate, newBookingEndDate, u);
    } else {
      createNewBooking(newBookingStartDate, newBookingEndDate, newUnitBooking);
    }
  };

  const updateUnitBookingRemarks = (unitIndex, remarks) => {
    const { units } = userBooking;
    units[unitIndex].remarks = remarks;

    updateBooking({ ...userBooking, units });
  };

  const updateBookingRemarks = remarks => {
    const { units } = userBooking;
    units.forEach(unit => (unit.remarks = remarks));

    updateBooking({ ...userBooking, units });
  };

  const clearBooking = useCallback(() => {
    removeCookie(COOKIE_KEY_BOOKING, cookieOptions);
  }, [cookieOptions, removeCookie]);

  const removeBookingUnit = unitIndex => {
    const { units } = userBooking;

    units.splice(unitIndex, 1);

    if (units.length === 0) {
      clearBooking();
      return;
    }

    updateBooking({ ...userBooking, units });
  };

  const removeMultipleBookingUnit = (unitIndex, count) => {
    const { units } = userBooking;

    units.splice(unitIndex, count);

    if (units.length === 0) {
      clearBooking();
      return;
    }

    updateBooking({ ...userBooking, units });
  };

  const checkIsUnitInBooking = unitId => {
    const { units } = userBooking;

    return !!units && !!units.find(unit => unit._id === unitId);
  };

  /* ----------------------------------------useEffect-----------------------------------------*/
  useEffect(() => {
    if (!checkIsObjectEmpty(userBooking) && checkIsDate('before', userBooking.startDate, getTodayMoment())) {
      clearBooking();
    }
  }, [userBooking, clearBooking]);

  return {
    userBooking,
    addUnitBooking,
    updateUnitBookingRemarks,
    updateBookingRemarks,
    clearBooking,
    removeBookingUnit,
    removeMultipleBookingUnit,
    checkIsUnitInBooking
  };
};

const BareUserContextProvider = withAppContext(({ host, children }) => {
  const hostId = guard(() => host._id, '');

  const cookieOptions = useMemo(
    () => ({
      path: `/${hostId}`,
      expires: getTomorrowMoment().toDate()
    }),
    [hostId]
  );

  const { searchAndFilter, updateSearchAndFilter } = useSearchAndFilter(cookieOptions);

  const {
    userBooking,
    addUnitBooking,
    updateUnitBookingRemarks,
    updateBookingRemarks,
    clearBooking,
    removeBookingUnit,
    removeMultipleBookingUnit,
    checkIsUnitInBooking
  } = useUserBooking(cookieOptions, updateSearchAndFilter);

  return (
    <UserContext.Provider
      value={{
        searchAndFilter,
        updateSearchAndFilter,
        userBooking,
        addUnitBooking,
        updateUnitBookingRemarks,
        updateBookingRemarks,
        removeBookingUnit,
        removeMultipleBookingUnit,
        clearBooking,
        checkIsUnitInBooking
      }}
    >
      {children}
    </UserContext.Provider>
  );
});

export const UserContextProvider = ({ children }) => {
  return (
    <CookiesProvider>
      <BareUserContextProvider>{children}</BareUserContextProvider>
    </CookiesProvider>
  );
};

export const UserContextConsumer = UserContext.Consumer;

export const withUserContext = Component => {
  const UserContextComponent = props => (
    <UserContextConsumer>{userContextProps => <Component {...userContextProps} {...props} />}</UserContextConsumer>
  );
  return UserContextComponent;
};
