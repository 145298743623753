import React, { useCallback } from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import PropTypes from 'prop-types';

import { addMoment, getBookingLimitDateMoment, getCurrentMoment, getStartOfDayMoment, formatToDateString } from 'utils/date';

const checkIsDisablePastDate = currentDate => currentDate < getStartOfDayMoment();
const checkIsDisableFutureDate = calendarDate => calendarDate > addMoment(getStartOfDayMoment(), 1, 'day');
const checkIsDisableTwoYearLater = currentDate => currentDate > getBookingLimitDateMoment();

const constructDisabledDate = (currentDate, { isDisablePastDate, isDisableFutureDate, isDisableTwoYearLater, customDisabledDate }) => {
  const formattedCurrentDate = getCurrentMoment(formatToDateString(currentDate));
  const isDisableCurrentPastDate = isDisablePastDate ? checkIsDisablePastDate(formattedCurrentDate) : false;
  const isDisableCurrentFutureDate = isDisableFutureDate ? checkIsDisableFutureDate(formattedCurrentDate) : false;
  const isDisableCurrentTwoYearLater = isDisableTwoYearLater ? checkIsDisableTwoYearLater(formattedCurrentDate) : false;
  const isDisableCurrentCustomDisableDate = customDisabledDate ? customDisabledDate(formattedCurrentDate) : false;

  return isDisableCurrentPastDate || isDisableCurrentFutureDate || isDisableCurrentTwoYearLater || isDisableCurrentCustomDisableDate;
};

const DatePicker = ({
  value,
  placeholder,

  isAllowClear,
  isDisabled,

  isDisablePastDate,
  isDisableFutureDate,
  isDisableTwoYearLater,
  customDisabledDate,

  onChange,
  updateFormValue
}) => {
  const handleOnChange = useCallback(
    value => {
      const formattedValue = !!value ? getCurrentMoment(formatToDateString(value)) : undefined;
      onChange(formattedValue);
      updateFormValue(formattedValue);
    },
    [onChange, updateFormValue]
  );

  return (
    <AntdDatePicker
      style={{ width: '100%' }}
      value={value}
      placeholder={placeholder}
      disabledDate={calendarDate =>
        constructDisabledDate(calendarDate, { isDisablePastDate, isDisableFutureDate, isDisableTwoYearLater, customDisabledDate })
      }
      allowClear={isAllowClear}
      disabled={isDisabled}
      onChange={handleOnChange}
    />
  );
};

DatePicker.propTypes = {
  value: PropTypes.object,
  placeholder: PropTypes.string,
  isAllowClear: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDisablePastDate: PropTypes.bool,
  isDisableFutureDate: PropTypes.bool,
  isDisableTwoYearLater: PropTypes.bool,
  customDisabledDate: PropTypes.func,
  onChange: PropTypes.func,
  updateFormValue: PropTypes.func
};

DatePicker.defaultProps = {
  value: undefined,
  placeholder: 'Select Date',
  isAllowClear: true,
  isDisabled: false,
  isDisablePastDate: true,
  isDisableFutureDate: false,
  isDisableTwoYearLater: true,
  customDisabledDate: undefined,
  onChange: () => {},
  updateFormValue: () => {}
};

export default DatePicker;
