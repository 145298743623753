import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { message } from 'antd';

import { withAppContext } from 'contexts/AppContext/AppContext';

import { checkIsStringTrue, constructQueryAsObject, constructQueryString } from 'utils/general';
import { buildBaseUri, buildThankYouUri, buildHomePage } from 'utils/routes';
// import { useGetHostById } from 'apis/host';

const useRedirectUser = (history, urlParams, hostId, hostHBE) => {
  // console.log(13, hostId, hostHBE);
  const redirectUser = useCallback(() => {
    if (!hostId) {
      return;
    }

    const { confirmationCodes, isPaymentCompleted: isPaymentCompletedInString } = constructQueryAsObject(urlParams);
    const isPaymentCompleted = checkIsStringTrue(isPaymentCompletedInString);

    if (isPaymentCompleted && !!confirmationCodes) {
      history.replace(`${buildThankYouUri(hostId)}?${constructQueryString({ confirmationCodes })}`);
      return;
    }

    message.warning('Payment was not success, please try again');
    if (hostHBE === true) {
      history.replace(buildHomePage(hostId));
    } else {
      history.replace(buildBaseUri(hostId));
    }
  }, [history, urlParams, hostId, hostHBE]);

  useEffect(() => {
    redirectUser();
  }, [redirectUser]);
};

// const useFetchHost = () => {
//   const { hostId } = useParams();

//   const {
//     isFetching,
//     data: { host },
//     error
//   } = useGetHostById(hostId);

//   const hostHBE = useMemo(() => guard(() => host.allowHotelBookingEngine, false), [host]);

//   return { isFetching, hostHBE: hostHBE, error };
// };

const StripePayment = ({ host }) => {
  const history = useHistory();
  const { search: urlParams } = useLocation();
  const hostId = useMemo(() => host._id, [host]);
  const hostHBE = useMemo(() => host.allowHotelBookingEngine, [host]);

  useRedirectUser(history, urlParams, hostId, hostHBE);

  return <div />;
};

export default withAppContext(StripePayment);
