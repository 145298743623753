import api from './apiHelper';

// ===================================================== GET

export const getBookingTotalPriceAfterPromotion = (hostId, { promotionCode, startDate, endDate, unitIds, addOnObj, extraGuestFee }) => {
  return api.get(`bookingEngine/${hostId}/booking/totalPrice`, { params: { promotionCode, startDate, endDate, unitIds, addOnObj, extraGuestFee } });
};

export const checkAvailabilityAndPricing = (hostId, { startDate, endDate, units }) => {
  return api.post(`bookingEngine/${hostId}/booking/checkAvailabilityAndPricing`, { startDate, endDate, units });
};

// ===================================================== POST CREATE
export const postCreateBooking = (
  hostId,
  { hCaptchaToken, startDate, endDate, guestDetails, units, paymentMethod, promotionCode, addOnObj, serviceFee }
) => {
  return api.post(`/bookingEngine/${hostId}/booking`, {
    hCaptchaToken,
    startDate,
    endDate,
    guestDetails,
    units,
    paymentMethod,
    promotionCode,
    addOnObj,
    serviceFee
  });
};
