import styled from '@emotion/styled';
import { Carousel as antdCarousel } from 'antd';

export const MainContainer = styled.div`
  background-color: #3d3d3d;
  border-radius: 2px;
`;

export const StyledCarousel = styled(antdCarousel)`
  height: 320px;
  line-height: 320px;
  overflow: hidden;
  text-align: center;
`;

export const StyledRoomTypeCarousel = styled(antdCarousel)`
  height: 240px;
  line-height: 240px;
  overflow: hidden;
  text-align: top;
`;
