import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { useGetTotalPriceForBooking, useGetUnitReservations } from 'apis/listing';

import { getBookingLimitDateMoment, getTodayMoment, checkIsDate, checkIsDateInBetween, formatToDateString } from 'utils/date';
import { constructDisplayPrice } from 'utils/general';

import DateRangePicker from 'components/DatePicker//DateRangePicker/DateRangePicker';

import {
  AddButton,
  AlreadyBookedIcon,
  AlreadyBookedRow,
  CleaningFeeCol,
  PricePerNightCol,
  PricePerNightSpan,
  StyledCard
} from './AddToBookingCard.styles';

const useFetchTotalPrice = (checkInDate, checkOutDate) => {
  const { hostId, unitId } = useParams();

  const {
    isLoading,
    data: { total, pricePerNight: actualPricePerNight }
  } = useGetTotalPriceForBooking({
    hostId,
    unitId,
    startDate: formatToDateString(checkInDate),
    endDate: formatToDateString(checkOutDate)
  });

  return { total, actualPricePerNight, isLoading };
};

const useUnitAvailability = (checkInDate, checkOutDate) => {
  const { hostId, unitId } = useParams();

  const {
    isLoading: isLoadingUnitReservation,
    data: { reservations }
  } = useGetUnitReservations(hostId, unitId, formatToDateString(getTodayMoment()), formatToDateString(getBookingLimitDateMoment()));

  const getLatestReservationBeforeCheckOutDate = useCallback(() => {
    if (!!checkOutDate) {
      const reservationsBeforeCheckOutDate = reservations.filter(reservation => checkIsDate('before', reservation.endDate, checkOutDate, true));
      const latestReservationBeforeCheckOutDate = reservationsBeforeCheckOutDate.sort((current, next) =>
        checkIsDate('after', next.endDate, current.endDate) ? 1 : -1
      )[0];

      return latestReservationBeforeCheckOutDate;
    }
  }, [reservations, checkOutDate]);

  const getEarliestReservationAfterCheckInDate = useCallback(() => {
    if (!!checkInDate) {
      const reservationAfterCheckInDate = reservations.filter(reservation => checkIsDate('after', reservation.startDate, checkInDate, true));
      const earliestReservationAfterCheckInDate = reservationAfterCheckInDate.sort((current, next) =>
        checkIsDate('before', next.startDate, current.startDate) ? 1 : -1
      )[0];

      return earliestReservationAfterCheckInDate;
    }
  }, [reservations, checkInDate]);

  const constructCheckInBookedDate = useCallback(
    currentCalendarDate => {
      const isBlockByReservation = !!reservations.find(reservation =>
        checkIsDateInBetween(currentCalendarDate, reservation.startDate, reservation.endDate, true, false)
      );

      const latestReservationBeforeCheckOutDate = getLatestReservationBeforeCheckOutDate();
      const isBlockByLatestReservations =
        !!latestReservationBeforeCheckOutDate && checkIsDate('before', currentCalendarDate, latestReservationBeforeCheckOutDate.endDate);

      return isBlockByReservation || isBlockByLatestReservations;
    },
    [reservations, getLatestReservationBeforeCheckOutDate]
  );

  const constructCheckOutBookedDate = useCallback(
    currentCalendarDate => {
      const isBlockByReservation = !!reservations.find(reservation =>
        checkIsDateInBetween(currentCalendarDate, reservation.startDate, reservation.endDate, false, false)
      );

      const earliestReservationAfterCheckInDate = getEarliestReservationAfterCheckInDate();
      const isBlockByEarliestReservations =
        !!earliestReservationAfterCheckInDate && checkIsDate('after', currentCalendarDate, earliestReservationAfterCheckInDate.startDate);

      return isBlockByReservation || isBlockByEarliestReservations;
    },
    [reservations, getEarliestReservationAfterCheckInDate]
  );

  console.log(99, reservations);

  return { isLoadingUnitReservation, constructCheckInBookedDate, constructCheckOutBookedDate };
};

const AddToBookingCard = ({ checkInDate, checkOutDate, isUnitBooked, pricePerNight, extraCharges, onAddUnitToBooking }) => {
  const [localCheckInDate, setLocalCheckInDate] = useState(checkInDate);
  const [localCheckOutDate, setLocalCheckOutDate] = useState(checkOutDate);

  const { actualPricePerNight, isLoading: isTotalPriceLoading } = useFetchTotalPrice(localCheckInDate, localCheckOutDate);
  const { isLoadingUnitReservation, constructCheckInBookedDate, constructCheckOutBookedDate } = useUnitAvailability(
    localCheckInDate,
    localCheckOutDate
  );

  const handleOnDateChange = (dates, { isOnChangeStartDate, isOnChangeEndDate }) => {
    const formattedDates = (isOnChangeStartDate && !dates[0]) || (isOnChangeEndDate && !dates[1]) ? [null, null] : dates;

    setLocalCheckInDate(formattedDates[0]);
    setLocalCheckOutDate(formattedDates[1]);
  };

  const handleOnAddUnitToBooking = e => {
    e.preventDefault();

    onAddUnitToBooking(formatToDateString(localCheckInDate), formatToDateString(localCheckOutDate), actualPricePerNight);
  };

  return (
    <StyledCard>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {isTotalPriceLoading ? (
            <Spin />
          ) : (
            <Row>
              <PricePerNightCol span={24}>
                <PricePerNightSpan>{constructDisplayPrice(actualPricePerNight || pricePerNight)}</PricePerNightSpan> / Night
              </PricePerNightCol>
              {!!extraCharges.cleaningFee && (
                <CleaningFeeCol span={24}>
                  <span>Cleaning Fee</span>
                  <span>{constructDisplayPrice(extraCharges.cleaningFee)}</span>
                </CleaningFeeCol>
              )}
            </Row>
          )}
        </Col>
        <Col span={24}>
          {isLoadingUnitReservation ? (
            <Spin />
          ) : (
            <DateRangePicker
              value={[localCheckInDate, localCheckOutDate]}
              placeholders={['Check-in', 'Check-out']}
              isDisablePastDate
              isDisableSameDate
              customDisabledDateFuncs={[constructCheckInBookedDate, constructCheckOutBookedDate]}
              onChange={handleOnDateChange}
            />
          )}
        </Col>
        <Col span={24}>
          <Row>
            {isUnitBooked ? (
              <AlreadyBookedRow justify="center" align="middle">
                <AlreadyBookedIcon />
                Added to your booking
              </AlreadyBookedRow>
            ) : (
              <AddButton type="primary" loading={isTotalPriceLoading} onClick={handleOnAddUnitToBooking}>
                add to booking
              </AddButton>
            )}
          </Row>
        </Col>
      </Row>
    </StyledCard>
  );
};

AddToBookingCard.propTypes = {
  pricePerNight: PropTypes.number.isRequired,
  onAddUnitToBooking: PropTypes.func.isRequired
};

AddToBookingCard.defaultProps = {
  onAddUnitToBooking: () => {}
};

export default AddToBookingCard;
