import React from 'react';

import { DescriptionRow, TitleRow } from './Description.styles';

const Description = ({ description }) => {
  return (
    <>
      <TitleRow>About this space</TitleRow>
      <DescriptionRow>{description}</DescriptionRow>
    </>
  );
};

export default Description;
