import React, { useState } from 'react';
import { Col, Form, Row } from 'antd';
import { FunnelPlotOutlined } from '@ant-design/icons';

import { getSingularOrPluralLabel } from 'utils/general';

import Filters from './Filters/Filters';
import FilterModal from './FilterModal/FilterModal';

import { FilterBarContainer } from './FilterBar.styles';

const MobileFilter = ({
  form,
  roomViewsSelection,
  sortingCriteriaSelection,
  propertySelection,
  roomTypeSelection,
  propertyId,
  roomTypeId,
  roomViews,
  sortingCriteria,
  totalNumberOfUnits,
  totalNumberOfRooms,
  onPropertyChange,
  onFilterModalConfirm,
  homepage,
  host
}) => {
  const [shouldShowFilterModal, setShouldShowFilterModal] = useState(false);

  const handleOnPropertyChange = propertyId => {
    onPropertyChange(propertyId, false);
  };

  const handleOnFilterButtonClick = () => {
    setShouldShowFilterModal(true);
  };

  const handleOnFilterModalClose = () => {
    setShouldShowFilterModal(false);
  };

  return (
    <>
      {!homepage && (
        <Row>
          <Col span={22} md={0}>
            Found <b>{host.allowBookingEngine ? totalNumberOfUnits : totalNumberOfRooms}</b>{' '}
            {getSingularOrPluralLabel(host.allowBookingEngine ? totalNumberOfUnits : totalNumberOfRooms, 'stay', { labelOnly: true })}
          </Col>
          <Col span={2} md={0}>
            <FunnelPlotOutlined onClick={handleOnFilterButtonClick} />
          </Col>
        </Row>
      )}
      {shouldShowFilterModal && !homepage && (
        <FilterModal
          form={form}
          roomViewsSelection={roomViewsSelection}
          sortingCriteriaSelection={sortingCriteriaSelection}
          propertySelection={propertySelection}
          roomTypeSelection={roomTypeSelection}
          propertyId={propertyId}
          roomTypeId={roomTypeId}
          roomViews={roomViews}
          sortingCriteria={sortingCriteria}
          onPropertyChange={handleOnPropertyChange}
          onFilterModalConfirm={onFilterModalConfirm}
          onFilterModalClose={handleOnFilterModalClose}
        />
      )}
    </>
  );
};

const WebFilter = ({
  form,
  roomViewsSelection,
  sortingCriteriaSelection,
  propertySelection,
  roomTypeSelection,
  propertyId,
  roomTypeId,
  roomViews,
  sortingCriteria,
  totalNumberOfUnits,
  onPropertyChange,
  onRoomTypeChange,
  onRoomViewsChange,
  onSortingCriteriachange,
  host,
  homepage
}) => {
  return (
    <>
      <Filters
        form={form}
        roomViewsSelection={roomViewsSelection}
        sortingCriteriaSelection={sortingCriteriaSelection}
        propertySelection={propertySelection}
        roomTypeSelection={roomTypeSelection}
        propertyId={propertyId}
        roomTypeId={roomTypeId}
        roomViews={roomViews}
        sortingCriteria={sortingCriteria}
        onPropertyChange={onPropertyChange}
        onRoomTypeChange={onRoomTypeChange}
        onRoomViewsChange={onRoomViewsChange}
        onSortingCriteriachange={onSortingCriteriachange}
        host={host}
        homepage={homepage}
      />

      {totalNumberOfUnits > 0 && host.allowBookingEngine && (
        <>
          <b>{totalNumberOfUnits}</b> {getSingularOrPluralLabel(totalNumberOfUnits, 'unit', { labelOnly: true })} to stay based on your search
        </>
      )}
    </>
  );
};

const FilterBar = ({
  roomViewsSelection,
  sortingCriteriaSelection,
  propertySelection,
  roomTypeSelection,
  propertyId,
  roomTypeId,
  roomViews,
  sortingCriteria,
  totalNumberOfUnits,
  totalNumberOfRooms,
  onPropertyChange,
  onRoomTypeChange,
  onRoomViewsChange,
  onSortingCriteriachange,
  onFilterModalConfirm,
  host,
  homepage
}) => {
  const [form] = Form.useForm();

  const handleOnPropertyChange = (propertyId, shouldFetchUnits) => {
    form.setFieldsValue({ roomType: undefined });
    onPropertyChange(propertyId, shouldFetchUnits);
  };

  return (
    <FilterBarContainer>
      <Col span={24} md={0}>
        <MobileFilter
          form={form}
          roomViewsSelection={roomViewsSelection}
          sortingCriteriaSelection={sortingCriteriaSelection}
          propertySelection={propertySelection}
          roomTypeSelection={roomTypeSelection}
          propertyId={propertyId}
          roomTypeId={roomTypeId}
          roomViews={roomViews}
          sortingCriteria={sortingCriteria}
          totalNumberOfUnits={totalNumberOfUnits}
          totalNumberOfRooms={totalNumberOfRooms}
          onPropertyChange={handleOnPropertyChange}
          onFilterModalConfirm={onFilterModalConfirm}
          host={host}
          homepage={homepage}
        />
      </Col>
      <Col span={0} md={24}>
        <WebFilter
          form={form}
          roomViewsSelection={roomViewsSelection}
          sortingCriteriaSelection={sortingCriteriaSelection}
          propertySelection={propertySelection}
          roomTypeSelection={roomTypeSelection}
          propertyId={propertyId}
          roomTypeId={roomTypeId}
          roomViews={roomViews}
          sortingCriteria={sortingCriteria}
          totalNumberOfUnits={totalNumberOfUnits}
          onPropertyChange={handleOnPropertyChange}
          onRoomTypeChange={onRoomTypeChange}
          onRoomViewsChange={onRoomViewsChange}
          onSortingCriteriachange={onSortingCriteriachange}
          host={host}
          homepage={homepage}
        />
      </Col>
    </FilterBarContainer>
  );
};

export default FilterBar;
