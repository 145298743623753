import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';

import { checkIsDate } from 'utils/date';

import DatePicker from '../DatePicker';

/* ================================================ Local Functions ================================================ */
// ================================================ General Functions
const checkIsAfterEndDate = (currentDate, endDate) => !!endDate && checkIsDate('after', currentDate, endDate);
const checkIsBeforeStartDate = (currentDate, startDate) => !!startDate && checkIsDate('before', currentDate, startDate);
const checkiIsDisableSameDate = (currentDate, selectedDate) => !!selectedDate && checkIsDate('same', currentDate, selectedDate);

// ================================================ Use Functions
const useOnDatesChange = (startDate, endDate, onChange, updateFormValue) => {
  const handleOnCalendarChange = useCallback(
    (dates, { isOnChangeStartDate, isOnChangeEndDate } = {}) => {
      onChange(dates, { isOnChangeStartDate, isOnChangeEndDate });
      updateFormValue(dates);
    },
    [onChange, updateFormValue]
  );

  const handleOnStartDateChange = useCallback(
    startDate => {
      const formattedDates = [startDate, endDate];
      handleOnCalendarChange(formattedDates, { isOnChangeStartDate: true });
    },
    [endDate, handleOnCalendarChange]
  );

  const handleOnEndDateChange = useCallback(
    endDate => {
      const formattedDates = [startDate, endDate];
      handleOnCalendarChange(formattedDates, { isOnChangeEndDate: true });
    },
    [startDate, handleOnCalendarChange]
  );

  return { onCalendarChange: handleOnCalendarChange, onStartDateChange: handleOnStartDateChange, onEndDateChange: handleOnEndDateChange };
};

const useConstructDisabledDates = (startDate, endDate, isDisableSameDate, customDisabledDateFuncs) => {
  const startDateCustomDisabledDate = useMemo(() => customDisabledDateFuncs[0], [customDisabledDateFuncs]);
  const endDateCustomDisabledDate = useMemo(() => customDisabledDateFuncs[1], [customDisabledDateFuncs]);

  const constructDisabledStartDate = useCallback(
    currentDate => {
      const isDisableAfterEndDate = checkIsAfterEndDate(currentDate, endDate);
      const isDisableCurrentSameDate = isDisableSameDate ? checkiIsDisableSameDate(currentDate, endDate) : false;
      const isDisableCurrentCustomDisableDate = startDateCustomDisabledDate ? startDateCustomDisabledDate(currentDate) : false;

      const isDisableDate = isDisableAfterEndDate || isDisableCurrentSameDate || isDisableCurrentCustomDisableDate;

      return isDisableDate;
    },
    [endDate, isDisableSameDate, startDateCustomDisabledDate]
  );

  const constructDisabledEndDate = useCallback(
    currentDate => {
      const isDisableBeforeStartDate = checkIsBeforeStartDate(currentDate, startDate);
      const isDisableCurrentSameDate = isDisableSameDate ? checkiIsDisableSameDate(currentDate, startDate) : false;
      const isDisableCurrentCustomDisableDate = endDateCustomDisabledDate ? endDateCustomDisabledDate(currentDate) : false;

      const isDisableDate = isDisableBeforeStartDate || isDisableCurrentSameDate || isDisableCurrentCustomDisableDate;

      return isDisableDate;
    },
    [startDate, isDisableSameDate, endDateCustomDisabledDate]
  );

  return { constructDisabledStartDate, constructDisabledEndDate };
};

const useResetDates = (startDate, endDate, constructDisabledStartDate, constructDisabledEndDate, onCalendarChange) => {
  useEffect(() => {
    const isCurrentStartDateDisabled = !!startDate && constructDisabledStartDate(startDate);
    if (isCurrentStartDateDisabled) {
      onCalendarChange([null, endDate]);
    }
  }, [startDate, endDate, constructDisabledStartDate, onCalendarChange]);

  useEffect(() => {
    const isCurrentEndDateDisabled = !!endDate && constructDisabledEndDate(endDate);
    if (isCurrentEndDateDisabled) {
      onCalendarChange([startDate, null]);
    }
  }, [startDate, endDate, constructDisabledEndDate, onCalendarChange]);
};

/* ================================================ Exported Function ================================================ */
const DateRangePicker = ({
  value: dates,
  placeholders,

  isAllowClear,
  isDisabled,

  isDisablePastDate,
  isDisableFutureDate,
  isDisableTwoYearLater,
  isDisableSameDate,
  customDisabledDateFuncs,

  onChange,
  updateFormValue
}) => {
  const startDate = useMemo(() => dates[0], [dates]);
  const endDate = useMemo(() => dates[1], [dates]);
  const startDatePlaceholder = useMemo(() => placeholders[0], [placeholders]);
  const endDatePlaceholder = useMemo(() => placeholders[1], [placeholders]);

  const { onCalendarChange, onStartDateChange, onEndDateChange } = useOnDatesChange(startDate, endDate, onChange, updateFormValue);
  const { constructDisabledStartDate, constructDisabledEndDate } = useConstructDisabledDates(
    startDate,
    endDate,
    isDisableSameDate,
    customDisabledDateFuncs
  );

  useResetDates(startDate, endDate, constructDisabledStartDate, constructDisabledEndDate, onCalendarChange);

  return (
    <Row gutter={[8, 0]}>
      <Col span={12}>
        <DatePicker
          value={startDate}
          placeholder={startDatePlaceholder}
          isAllowClear={isAllowClear}
          isDisabled={isDisabled}
          isDisablePastDate={isDisablePastDate}
          isDisableFutureDate={isDisableFutureDate}
          isDisableTwoYearLater={isDisableTwoYearLater}
          customDisabledDate={constructDisabledStartDate}
          onChange={onStartDateChange}
        />
      </Col>
      <Col span={12}>
        <DatePicker
          value={endDate}
          placeholder={endDatePlaceholder}
          isAllowClear={isAllowClear}
          isDisabled={isDisabled}
          isDisablePastDate={isDisablePastDate}
          isDisableFutureDate={isDisableFutureDate}
          isDisableTwoYearLater={isDisableTwoYearLater}
          customDisabledDate={constructDisabledEndDate}
          onChange={onEndDateChange}
        />
      </Col>
    </Row>
  );
};

DateRangePicker.propTypes = {
  value: PropTypes.array,
  placeholders: PropTypes.array,
  isDisableSameDate: PropTypes.bool,
  customDisabledDateFuncs: PropTypes.array,
  onChange: PropTypes.func,
  updateFormValue: PropTypes.func
};

DateRangePicker.defaultProps = {
  value: [undefined, undefined],
  placeholders: [undefined, undefined],
  isDisableSameDate: false,
  customDisabledDateFuncs: [undefined, undefined],
  onChange: () => {},
  updateFormValue: () => {}
};

export default DateRangePicker;
