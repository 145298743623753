import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Skeleton } from 'antd';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';

import { useGetHostById } from 'apis/host';

import { guard } from 'utils/general';
import { build404Uri } from 'utils/routes';

const AppContext = React.createContext();

export const useFetchHost = () => {
  const { hostId } = useParams();

  const {
    isFetching,
    data: { host },
    error
  } = useGetHostById(hostId);

  const localHost = useMemo(() => guard(() => host, {}), [host]);

  return { isFetching, host: localHost, error };
};

const useTheme = host => {
  const originalTheme = useMemo(
    () => ({
      color: {
        primary: '#00b9c6',
        success: '#52c41a',
        text: '#4f4f4f',
        textSecondary: '#707070'
      },
      background: ''
    }),
    []
  );
  const [theme, setTheme] = useState(originalTheme);

  useEffect(() => {
    if (host && host.bookingEngine && host.bookingEngine.primaryColor) {
      const hostPrimaryColor = host.bookingEngine.primaryColor;

      setTheme({ ...originalTheme, color: { ...originalTheme.color, primary: hostPrimaryColor } });
      window.less.modifyVars({ '@primary-color': hostPrimaryColor });
    }
    if (host && host.bookingEngine && host.bookingEngine.background) {
      const hostBackground = host.bookingEngine.background;

      setTheme({ ...originalTheme, background: hostBackground });
    }
  }, [host, originalTheme]);
  return theme;
};

export const AppContextProvider = ({ children }) => {
  const { isFetching, host, error } = useFetchHost();
  const theme = useTheme(host);

  if (!!error) {
    return <Redirect from="*" to={build404Uri()} />;
  } else {
    return (
      <Skeleton loading={isFetching} active>
        <AppContext.Provider value={{ host }}>
          <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
        </AppContext.Provider>
      </Skeleton>
    );
  }
};

export const AppContextConsumer = AppContext.Consumer;

export const withAppContext = Component => {
  const AppContextComponent = props => <AppContextConsumer>{appContextProps => <Component {...appContextProps} {...props} />}</AppContextConsumer>;
  return AppContextComponent;
};
