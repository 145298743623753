import React from 'react';
import PropTypes from 'prop-types';

import { EMAIL_REGEX, CONTACT_NUMBER_REGEX } from 'utils/regex';

import Input from '../Input';
import FormItem from 'components/FormItem/FormItem';

const generateExtraGeneralRules = ({ isEmail, isContactNumber }) => {
  let extraGeneralRules = [];

  if (isEmail) {
    extraGeneralRules.push({
      pattern: EMAIL_REGEX,
      message: 'Please enter a valid email address.'
    });
  }

  if (isContactNumber) {
    extraGeneralRules.push({
      pattern: CONTACT_NUMBER_REGEX,
      message: 'Please enter a valid contact number.'
    });
  }

  return extraGeneralRules;
};

const FormInput = ({
  noStyle,
  type,
  name,
  label,
  labelSize,
  placeholder,
  prefix,
  autoComplete,
  maxLength,
  size,

  requiredErrorMessage,
  extraRules,

  isEmail,
  isContactNumber,
  isDisabled,

  onChange,
  uppercase,
  ...props
}) => {
  const extraGeneralRules = generateExtraGeneralRules({ isEmail, isContactNumber });

  return (
    <FormItem
      noStyle={noStyle}
      name={name}
      label={label}
      labelSize={labelSize}
      requiredErrorMessage={requiredErrorMessage}
      extraRules={[...extraRules, ...extraGeneralRules]}
      uppercase={uppercase}
      {...props}
    >
      <Input
        type={type}
        autoComplete={autoComplete}
        placeholder={placeholder}
        prefix={prefix}
        isDisabled={isDisabled}
        maxLength={maxLength}
        size={size}
        onChange={onChange}
        {...props}
      />
    </FormItem>
  );
};

FormInput.propTypes = {
  extraRules: PropTypes.array,
  maxLength: PropTypes.number,
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  isEmail: PropTypes.bool,
  isContactNumber: PropTypes.bool
};

FormInput.defaultProps = {
  extraRules: [],
  size: 'middle',
  isEmail: false,
  isContactNumber: false
};

export default FormInput;
