import axios from 'axios';
import env from 'config/env';

import { guard } from 'utils/general';

const FIELDS_SEPERATOR = ',';

const api = axios.create({
  baseURL: env.REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json'
  }
});

const apiErrorHandler = e => {
  const errorData = guard(() => e.response.data);
  const errorMessage = e.message;

  if (!!errorData) {
    if (errorData.code) {
      throw errorData;
    } else if (errorData.message) {
      throw new Error(errorData.message);
    } else if (errorData.error) {
      throw new Error(errorData.error);
    } else {
      throw new Error(errorData);
    }
  } else if (!!errorMessage) {
    throw e;
  } else {
    throw new Error('Unexpected error occured when calling api. Please contact our support');
  }
};

const constructAndMutateOptions = params => {
  // fields
  if (!!params && !!params.fields) {
    params._fields = params.fields;
    delete params.fields;
  }
};

const mutateParamsArrayToString = params => {
  if (!!params) {
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const valueInString = value.join(FIELDS_SEPERATOR);
        params[key] = valueInString;
      }
    });
  }
};

api.interceptors.request.use(req => {
  constructAndMutateOptions(req.params);
  mutateParamsArrayToString(req.params);
  return req;
});
api.interceptors.response.use(res => res.data, apiErrorHandler);

export default api;
