import React from 'react';
import ReactHCaptcha from '@hcaptcha/react-hcaptcha';

import { HCAPTCHA_SITE_KEY, NODE_ENV } from 'config/env';
import { NODE_ENV_DEVELOPMENT } from 'utils/constants';

const IS_DEVELOPMENT_ENV = NODE_ENV === NODE_ENV_DEVELOPMENT;

const HCaptcha = ({ onVerify }) => {
  return IS_DEVELOPMENT_ENV ? <>~This is hCaptcha in dev mode~</> : <ReactHCaptcha sitekey={HCAPTCHA_SITE_KEY} onVerify={onVerify} />;
};

export default HCaptcha;
