import React from 'react';
import { Col } from 'antd';

import { getSingularOrPluralLabel } from 'utils/general';

import { UnitLocationRow, UnitNameRow, UnitOccupancyRow } from './GeneralInfo.styles';

const GeneralInfo = ({ unit }) => {
  const { adultCapacity, childCapacity, city, name, noOfBathrooms, noOfBedrooms, noOfLivingRooms, state } = unit;

  return (
    <>
      <UnitNameRow>{name}</UnitNameRow>
      <UnitLocationRow>
        {city}, {state}
      </UnitLocationRow>
      <UnitOccupancyRow>
        {adultCapacity > 0 && (
          <Col span={childCapacity > 0 ? 8 : 24} lg={4}>
            {getSingularOrPluralLabel(adultCapacity, 'adult')}
          </Col>
        )}
        {childCapacity > 0 && (
          <Col span={adultCapacity > 0 ? 16 : 24} lg={4}>
            {getSingularOrPluralLabel(childCapacity, 'child', { pluralLabel: 'children' })}
          </Col>
        )}
        <Col span={8} lg={4}>
          {getSingularOrPluralLabel(noOfBedrooms, 'bedroom')}
        </Col>
        <Col span={8} lg={4}>
          {getSingularOrPluralLabel(noOfBathrooms, 'bathroom')}
        </Col>
        <Col span={8} lg={4}>
          {getSingularOrPluralLabel(noOfLivingRooms, 'living room')}
        </Col>
      </UnitOccupancyRow>
    </>
  );
};

export default GeneralInfo;
