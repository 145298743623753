import React from 'react';

import {
  StyledForm,
  StyledPropertySelection,
  StyledRoomTypeSelection,
  StyledRoomViewsSelection,
  StyledSortingCriteriaSelection
} from './Filters.styles';

const Filters = ({
  form,
  roomViewsSelection,
  sortingCriteriaSelection,
  propertySelection,
  roomTypeSelection,
  propertyId,
  roomTypeId,
  roomViews,
  sortingCriteria,
  onPropertyChange,
  onRoomTypeChange,
  onRoomViewsChange,
  onSortingCriteriachange,
  host,
  homepage
}) => {
  if (host.allowBookingEngine === false) {
    return (
      <StyledForm
        form={form}
        initialValues={{
          property: propertyId,
          sortingCriteria
        }}
      >
        {homepage === false && (
          <StyledPropertySelection name="property" selections={propertySelection} placeholder="Property name" onChange={onPropertyChange} />
        )}
        {/* {homepage === false && <StyledSortingCriteriaSelection
          name="sortingCriteria"
          selections={sortingCriteriaSelection}
          isAllowClear={false}
          onChange={onSortingCriteriachange}
        />} */}
      </StyledForm>
    );
  }
  return (
    <StyledForm
      form={form}
      initialValues={{
        property: propertyId,
        roomType: roomTypeId,
        roomViews,
        sortingCriteria
      }}
    >
      <StyledPropertySelection name="property" selections={propertySelection} placeholder="Property name" onChange={onPropertyChange} />
      <StyledRoomTypeSelection name="roomType" selections={roomTypeSelection} placeholder="Types of room / units" onChange={onRoomTypeChange} />
      <StyledRoomViewsSelection
        isMultiple={true}
        name="roomViews"
        selections={roomViewsSelection}
        placeholder="Views of room / units"
        onChange={onRoomViewsChange}
      />
      <StyledSortingCriteriaSelection
        name="sortingCriteria"
        selections={sortingCriteriaSelection}
        isAllowClear={false}
        onChange={onSortingCriteriachange}
      />
    </StyledForm>
  );
};

export default Filters;
