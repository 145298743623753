import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { message } from 'antd';

const useDisplayError = (shouldDisplayError, error, prefixErrorMessage) => {
  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);

  if (!isErrorDisplayed && shouldDisplayError && !!error) {
    setIsErrorDisplayed(true);
    console.error(error);

    const baseErrorMessage = error.message || 'Something went wrong while retriving data, please contact our technical support';
    const errorMessage = `${prefixErrorMessage}${baseErrorMessage}`;
    message.error(errorMessage);
  }
};

export const useCustomQuery = (
  key,
  paramsInArray = [],
  apiFunction,
  { shouldDisplayError = true, shouldDefaultEmptyObject = true, prefixErrorMessage = '', shouldFetch = true, ...queryOptions } = {}
) => {
  const { data, isFetching, error, ...returnedParams } = useQuery(shouldFetch && [key, ...paramsInArray], apiFunction, queryOptions);
  useDisplayError(shouldDisplayError, error, prefixErrorMessage);

  const returnedData = useMemo(() => data || (shouldDefaultEmptyObject ? {} : data), [shouldDefaultEmptyObject, data]);

  // isFetching is always true even when it is not fetching.
  return { isLoading: shouldFetch && isFetching, data: returnedData, error, ...returnedParams };
};
