import styled from '@emotion/styled';
import { Row } from 'antd';

export const MainContainer = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  margin-top: 24px;
  background-color: white;
  color: ${props => props.theme.color.text};

  @media (min-width: 768px) {
    padding: 8px 0;
  }
`;
