import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ReactQueryConfigProvider } from 'react-query';
import '@stripe/stripe-js'; // refer https://github.com/stripe/stripe-js, mainly to detect fraud through the whole system

import { AppContextProvider } from 'contexts/AppContext/AppContext';
import { UserContextProvider } from 'contexts/UserContext/UserContext';

import StandardLayout from 'layouts/StandardLayout/StandardLayout';

import NotFound404 from 'pages/404/NotFound404';
import StripePayment from 'pages/StripePayment/StripePayment';
import ThankYou from 'pages/ThankYou/ThankYou';
import UnitListing from 'pages/UnitListing/UnitListing';
import UnitDetails from 'pages/UnitDetails/UnitDetails';

import {
  build404Uri,
  buildBaseUri,
  buildHomePage,
  buildPropertyBaseUri,
  buildCheckOutUri,
  buildStripPaymentUri,
  buildThankYouUri,
  buildUnitDetailUri
} from 'utils/routes';

import * as serviceWorker from './serviceWorker';
import './index.css';
import Homepage from 'pages/HomePage/Homepage';
import CheckOut from 'pages/Checkout/CheckOut';

const queryConfig = {
  retry: false,
  refetchOnWindowFocus: false
};

const App = () => {
  return (
    <AppContextProvider>
      <UserContextProvider>
        <StandardLayout>
          <Switch>
            <Route exact path={buildStripPaymentUri()}>
              <StripePayment />
            </Route>
            <Route exact path={buildThankYouUri()}>
              <ThankYou />
            </Route>
            <Route exact path={buildBaseUri()}>
              <UnitListing />
            </Route>
            <Route exact path={buildHomePage()}>
              <Homepage />
            </Route>
            <Route exact path={buildPropertyBaseUri()}>
              <UnitListing />
            </Route>
            <Route exact path={buildCheckOutUri()}>
              <CheckOut />
            </Route>
            <Route exact path={buildUnitDetailUri()}>
              <UnitDetails />
            </Route>
            <Redirect from="*" to={build404Uri()} />
          </Switch>
        </StandardLayout>
      </UserContextProvider>
    </AppContextProvider>
  );
};

ReactDOM.render(
  <ReactQueryConfigProvider config={queryConfig}>
    <Router>
      <Switch>
        <Route exact path={build404Uri()}>
          <NotFound404 />
        </Route>
        <Route path={buildBaseUri()}>
          <App />
        </Route>
        <Route path={buildHomePage()}>
          <App />
        </Route>
        <Redirect from="*" to={build404Uri()} />
      </Switch>
    </Router>
  </ReactQueryConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
