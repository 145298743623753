import styled from '@emotion/styled';
import { Skeleton } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';

export const StyledHeader = styled(Skeleton)`
  height: 35%;
`;

export const StyledBody = styled(Skeleton)`
  height: 100%;
`;

export const AddIcon = styled(ShoppingCartOutlined)`
  margin-right: 4px;
`;
