import React from 'react';

import NoImagePlaceholder from 'images/no-image-placeholder.jpg';

import { StyledImage } from './BareImage.styles';

const BareImage = ({ image, alt, onClick }) => {
  if (!!image) {
    return <StyledImage key={image} alt={alt} src={image} onClick={onClick} />;
  } else {
    return <NoImage alt={`${alt} is not found`} onClick={onClick} />;
  }
};

export default BareImage;

export const NoImage = ({ alt, onClick }) => {
  return <StyledImage alt={`${alt} is not found`} src={NoImagePlaceholder} onClick={onClick} />;
};
