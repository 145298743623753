export const NODE_ENV_DEVELOPMENT = 'development';

/* ----------------------------General Constants---------------------------- */
/* ---------------The constants that generally use everywhere--------------- */
// Date
export const BOOK_DAY_LIMIT = 729;

// Date format
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_WITH_WEEKDAY_FORMAT = 'YYYY-MM-DD, dddd';
export const FORMAL_DATE_FORMAT = 'DD-MM-YYYY';
export const MONTH_FORMAT = `YYYY-MM`;
export const DATE_DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;

export const constructDateFormat = (separator = '-') => `YYYY${separator}MM${separator}DD`;

// Form Label
export const LABEL_SIZE_XXLARGE = 'xxlarge';
export const LABEL_SIZE_XLARGE = 'xlarge';
export const LABEL_SIZE_LARGE = 'large';
export const LABEL_SIZE_REGULAR = 'regular';
export const LABEL_SIZE_SMALL = 'small';

// Regex
export const REGEX_NOT_NUMBER_N_DOT = /[^0-9.]/g;

// Image
export const BLANK_IMAGE = 'https:dashboard-operator-image.s3.amazonaws.com/3386bffc-75c9-4390-a670-fc11ed8febe1_blanksquare.png';

/* ----------------------------Specific Constants---------------------------- */
/* ---------------The constants that generally use in one page--------------- */
// Unit Listings Page
export const SORTING_CRITERIAS = [
  { value: 'priceAscending', label: 'Lowest to Highest Price', isDefault: true },
  { value: 'priceDescending', label: 'Highest to Lowest Price' }
];
