import React from 'react';

import ExternalLink from 'components/ExternalLink/ExternalLink';
import { Badge } from 'antd';

import { MainContainer, StyledAvatar, StyledTab } from './Header.styles';

const Header = ({ hostHomepageUrl, hostImageURL, onOpenDrawer, count, hideDrawer = false }) => {
  return (
    <MainContainer>
      <span>
        <ExternalLink to={hostHomepageUrl}>
          <StyledAvatar size={{ xs: 140, sm: 140, md: 150, lg: 110, xl: 100, xxl: 80 }} src={hostImageURL} />
        </ExternalLink>
      </span>
      {!hideDrawer && (
        <span onClick={onOpenDrawer}>
          <Badge count={count} offset={[10]}>
            <StyledTab>MY BOOKING</StyledTab>
          </Badge>
        </span>
      )}
    </MainContainer>
  );
};

export default Header;
