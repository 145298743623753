import React, { useState, useEffect } from 'react';
import { Input, Modal } from 'antd';
import PropTypes from 'prop-types';

import { ConfirmButton } from './RemarksModal.styles';

const { TextArea } = Input;

const RemarksModal = ({ unit, visible, onCancel, onConfirm, isHotel = false }) => {
  const { name, roomTypeName } = unit;
  const [editedRemarks, setEditedRemarks] = useState('');

  useEffect(() => {
    const { remarks } = unit;
    setEditedRemarks(remarks);
  }, [unit]);

  const handleOnChangeRemarks = e => {
    setEditedRemarks(e.target.value);
  };

  const handleOnConfirm = () => {
    onConfirm(editedRemarks);
    onCancel();
  };

  return (
    <Modal
      bodyStyle={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      visible={visible}
      destroyOnClose={true}
      title={`Remarks for ${isHotel ? roomTypeName : name}`}
      footer={null}
      onCancel={onCancel}
    >
      <TextArea rows={4} value={editedRemarks} onChange={handleOnChangeRemarks} onPressEnter={handleOnConfirm} />
      <ConfirmButton type="primary" onClick={handleOnConfirm}>
        add remarks
      </ConfirmButton>
    </Modal>
  );
};

RemarksModal.propTypes = {
  unit: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

RemarksModal.defaultProps = {
  unit: {},
  visible: false,
  onCancel: () => {},
  onConfirm: () => {}
};

export default RemarksModal;
