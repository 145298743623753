import styled from '@emotion/styled';
import { ArrowUpOutlined } from '@ant-design/icons';

export const StyledHeader = styled.div`
  min-height: 11vh;
  display: flex;
  align-items: center;
  padding: 0 8%;
`;

export const StyledBody = styled.div`
  min-height: 80vh;
  padding: 0 8%;
`;

export const StyledFooter = styled.div`
  min-height: 8vh;
  display: flex;
  align-items: center;
  padding: 0 8%;
`;

export const StyledUpButton = styled(ArrowUpOutlined)`
  position: absolute;
  margin-bottom: -100px;
  cursor: pointer;
`;
