import { Button } from 'antd';
import styled from '@emotion/styled';

export const BackButton = styled(Button)`
  color: ${props => props.theme.color.text};
  font-size: 16px;
  border: none !important;
  height: auto;
  margin-bottom: 16px;
  padding: 4px 0px;
`;
