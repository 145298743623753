import api from './apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

export const useGetPropertiesByHost = (hostId, { fields, startDate, endDate } = {}) => {
  const getPropertiesByHost = (key, hostId, fields) => {
    return api.get(`bookingEngine/${hostId}/property`, { params: { fields, startDate, endDate } });
  };

  return useCustomQuery('property', [hostId, fields], getPropertiesByHost, { shouldFetch: !!hostId });
};
