import qs from 'query-string';

/* ------------------------------------------------General function------------------------------------------------- */
export const checkIsObjectEmpty = object => {
  return Object.keys(object).length === 0;
};

export const constructDisplayNumber = (number, decimal = 2) => {
  const numberWithDecimal = guard(() => number.toFixed(decimal), (0).toFixed(decimal));
  const numberWithDecimalAndComma = numberWithDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return numberWithDecimalAndComma;
};

export const getSingularOrPluralLabel = (amount, singularLabel, { pluralLabel, labelOnly } = {}) => {
  const returnLabel = Number(amount) === 1 ? singularLabel : pluralLabel || `${singularLabel}s`;
  const returnAmount = labelOnly ? '' : amount;

  return `${returnAmount} ${returnLabel}`.trim();
};

export const guard = (callback, fallbackValue) => {
  try {
    const value = callback();
    if (value === undefined || value === null) {
      return fallbackValue;
    }

    return value;
  } catch {
    return fallbackValue;
  }
};

export const constructQueryAsObject = queryString => {
  return qs.parse(queryString);
};

export const constructQueryString = queryObject => {
  return qs.stringify(queryObject);
};

export const checkIsStringTrue = booleanInString => {
  if (booleanInString === 'true') {
    return true;
  } else if (booleanInString === 'false') {
    return false;
  } else {
    return undefined;
  }
};

/* ------------------------------------------------Specific function------------------------------------------------- */
// Rate
export const constructDisplayPrice = (feeAmount, hasPrefix = true) => {
  const displayFee = String(constructDisplayNumber(feeAmount));
  const displayFeeWithPrefix = hasPrefix ? `RM ${displayFee}` : displayFee;

  return displayFeeWithPrefix;
};

export const constructDisplayPercentage = (percentage, isString) => {
  const numberPercentage = percentage * 100;
  const displayPercentage = `${numberPercentage}%`;

  return isString ? displayPercentage : numberPercentage;
};

export const rounds = (value, decimals) => {
  return Number(`${Math.round(`${Number(value).toFixed(20)}e${decimals}`)}e-${decimals}`);
};
