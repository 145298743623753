import styled from '@emotion/styled';
import { Button } from 'antd';

export const InputContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaymentTypeRadio = styled.div`
  text-transform: uppercase;
  text-align: center;
  padding: 16px;
  width: 124px;
`;

export const PaymentTypeRadioIcon = styled.div`
  font-size: 24px;
`;

export const PaymentTypeRadioText = styled.div`
  text-transform: uppercase;
`;

export const SubmitButton = styled(Button)`
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 8px;
  width: 250px;
  height: auto;
`;

export const StyledBookingImage = styled.img`
  max-width: 50%;
`;
