import styled from '@emotion/styled';
import { CheckCircleOutlined, PlusSquareOutlined, MinusSquareOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Row, Badge } from 'antd';

import FormSelection from '../../../components/Selection/FormSelection/FormSelection';

export const CardContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 44px;

  :hover {
    cursor: pointer;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const StyledCardImageContainer = styled.div`
  min-height: 100px;
  height: 20vh;
`;

export const StyledTextUnitName = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${props => props.theme.color.text};
`;

export const StyledTextPropertyDetails = styled.div`
  font-size: 18px;
  white-space: pre-wrap;
  color: ${props => props.theme.color.textSecondary};
`;

export const StyledTextUnitAddress = styled.div`
  color: ${props => props.theme.color.textSecondary};
  margin-bottom: 16px;
`;

export const StyledTextUnitPrice = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.color.text};
`;

export const StyledTextUnitPriceLabel = styled.span`
  color: ${props => props.theme.color.textSecondary};
`;

export const StyledTextPax = styled.div`
  color: ${props => props.theme.color.textSecondary};
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 40px;
  font-size: 14px;
  text-transform: uppercase;
`;

export const AlreadyBookedRow = styled(Row)`
  width: 100%;
  height: 40px;
  text-transform: uppercase;
  background-color: limegreen;
  color: white;
  font-size: 14px;
`;

export const AlreadyBookedIcon = styled(CheckCircleOutlined)`
  margin-right: 2px;
`;

export const MoreDetailsIcon = styled(PlusSquareOutlined)`
  margin-right: 4px;
`;

export const LessDetailsIcon = styled(MinusSquareOutlined)`
  margin-right: 4px;
`;

export const ShareAltOutlinedIcon = styled(ShareAltOutlined)`
  font-size: 32px;
  margin-right: 4px;
`;

export const ShowDetailsRow = styled(Row)`
  color: rgb(23, 93, 133);
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 2px;
  margin-top: 20px;
  align-items: bottom;
`;

export const StyledEmpty = styled(Empty)`
  margin-top: 80px;
`;

export const Banner = styled(Row)`
  background-color: rgb(231, 253, 255);
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
`;

export const Ribbon = styled(Badge.Ribbon)`
  font-family: Lato;
  padding: 15px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 700;
`;

export const StyledNumberSelection = styled(FormSelection)`
 width= 100%;
`;

export const BackButton = styled(Button)`
  color: ${props => props.theme.color.text};
  font-size: 16px;
  border: none !important;
  height: auto;
  margin-bottom: 16px;
  padding: 4px 0px;
`;
